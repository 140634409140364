/* eslint-disable */
import React from "react";
import { withRouter } from "react-router";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
// @material-ui/icons
import AddAlert from "@material-ui/icons/AddAlert";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import CustomInput from "components/CustomInput/CustomInput.jsx";

import { API_GET, API_POST,API_UPLOAD } from "../../services/api.service";
import { category, subCategory, productsList, status , superCategory,superCategorymanager } from "./constants"


import Loader from "components/Loader/Loader.jsx";
import DataTable from "react-data-table-component";
import { identity } from "underscore";
import SupportFilter from "./SupportFilter";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Modal, Tab, TextField } from "@material-ui/core";
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import { Close, GetAppOutlined } from "@material-ui/icons";
import CallbackControl from "../Forms/CallbackControl";
import { getManager } from "../common/helper";

const { REACT_APP_SERVER_URL } = process.env; 

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0",
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF",
        },
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1",
        },
    },
};

class Support extends React.Component {
    constructor(props) {
        super(props);
        const { user } = this.props;

        this.state = {
            teamuser: [{ key: 'Select', value: 0 }],
            showLoader: true,
            editMode: false,
            allocatedUsers:[],
            _id: null,
            editId: '',
            mode: "Add",
            records: null,
            rowCount:0,
            allUser:null,
            query:{},
            results:null,
            activeTab:2,
            pendingCount:0,
            resolvedCount:0,
            roadmapCount:0,
            commentRequired:false,
            isResolved: true,
            otp: null,
            otpNumber: null,
            ticketAssignedTo:null,
            ticketAssignedToOption:null,
            notify: {
                open: false,
                message: "",
                color: "success",
            },
        };
        const defaultData = {
            category: null,
            product: 'Betyphon',
            subCategory: null,
            comment: "",
            status: "Pending",
            adminComment: "",
            IsActive: true,
            createdOn: new Date(),
            updatedOn: null,
            updatedBy: null,
            admin: user && user.admin,
            user: user && user.username,
            userOption: null,
            externalQuery: false,
            company: user && user.company,
            scheduledTime: null
        };



        this.state.catoption = category;
        this.state.superCategory = (user.is_superuser && user.role =='manager') ? superCategorymanager :superCategory;
        this.state.statusOption = status;
        this.state.subcatoption = [];
        this.state.pop = false;
        this.state.productsList = productsList;
        this.state.notificationDetails = defaultData;
        this.state.defaultData = defaultData;

        this.state.rowsPerPage = 50;
        this.state.skip = 0;
        this.state.selectedFiles = [];


    }

    async getRMAllocatedUsers(userid){
        let userInfo = await API_GET(`register/allocatedUsers?id=${userid}`);
        if (userInfo.success) {
          if(!userInfo.data.length) return true;
          this.setState({ allocatedUsersObj: userInfo.data,allocatedUsers: userInfo.data.map(v=> v.email) });
        } else {
          this.setState({ allocatedUsers: [] });
        }
      }
    
    handleFileChange = async (e) => {
        console.log(e.target)
        const files = Array.from(e.target.files);
        this.setState({selectedFiles:files});
    };

    handleChangePage = async (newPage) => {
        this.setState({skip:(newPage - 1) * this.state.rowsPerPage});
        const { user } = this.props;
        let querys = this.state.query;
        this.loadRecords(user,querys);
      
    };
    handleClose = async (e)=>{
        const { user, history } = this.props;
        this.setState({ pop: false });
        history.push("/admin/support");
    }

    categoryChange = async (e) => {

        //this.state.notificationDetails.category = e.target.value;
        this.setState({ notificationDetails: { ...this.state.notificationDetails, category: e.target.value } })


        let subcat = subCategory[e.target.value];
        let option = [];

        for (let i in subcat) {
            option.push({
                key: subcat[i],
                value: subcat[i]
            });
        }

        console.log(option);
        this.setState({ subcatoption: option });

    }


    changesubcat = async (e) => {
        //this.state.notificationDetails.subCategory = e.target.value;
        this.setState({ notificationDetails: { ...this.state.notificationDetails, subCategory: e.target.value } })
    }

    changeAdmin = async (data) => {
        //this.state.notificationDetails.subCategory = e.target.value;
        if (data) {
            this.setState({ notificationDetails: { ...this.state.notificationDetails,selectAdminObj:data, admin: data.title, user: data.title, company: data.data.company, phone: data.data.phone } })
        }

    }


    filterUpdate = async (data) => {

        this.setState({ activeTab: 1 });
        const { user } = this.props;
        let querys = this.state.query;

        if (!user.is_superuser) {
            querys = { admin: user.admin };
        }

        console.log(data);
        if (data.company) querys.company = { $regex: '^'+data.company, $options: 'i' };
        if (data.admin) querys.$or = [{ admin: { $regex: '^'+data.admin, $options: 'i' } }, { externalAdmin: { $regex: data.admin, $options: 'i' } }];
        if (data.category) querys.category = { $regex: '^'+data.category, $options: 'i' };
        if (data.subCategory) querys.subCategory = { $regex: '^'+data.subCategory, $options: 'i' };
        if (data.status) querys.status = { $regex: '^'+data.status, $options: 'i' };
        if (data.product) querys.product = { $regex: '^'+data.product, $options: 'i' };
        if (data.ticketAssignedTo) querys.ticketAssignedTo = { $regex: '^'+data.ticketAssignedTo, $options: 'i' };
        if (data.startDate) {
            querys.createdOn = {
                $gte: new Date(data.startDate),
                $lt: new Date(data.endDate),
            }
        }

        console.log(querys, data);
        this.setState({ query: querys });
        this.loadRecords(user, querys);
    }


    loadTeam = async () =>{
        try{
            const { user } = this.props;
            let userInfo = await API_GET(`register/GetAllMembersByUserId?id=8`);
            if (userInfo.success) {
                userInfo = userInfo.data;

                let list =[];
                for (let index = 0; index < userInfo.ActiveMembers.length; index++) {
                    let element = userInfo.ActiveMembers[index];
                      list.push({
                        key: element.username,
                        value: element.username+"--"+element.first_name,
                      });
            
                }
                if(user.is_superuser && user.role !='owner'){
                    list = list.filter((v)=> (v.key == user.email || v.key == user.admin ))
                }
                console.log("tttttttttttttt",list);

                this.setState({ticketAssignedToOption:list})
            }
        }catch(e){

            console.log("errrr",e)
        }
    }

    // to stop the warning of calling setState of unmounted component
    async componentDidMount() {
        const { user } = this.props;
        let id = this.props.match.params.id;
        //alert(JSON.stringify(this.props.match));
        if (id) {
            // alert("edit");
            this.setState({ editMode: true, commentRequired: true });
            this.setState({ editId: id });
            this.editRecord(id);


        }
        this.setState({ showLoader: false });
        if (user) {
            if(user.admin == 'mark@gmail.com' && user.role !='owner'){
                await this.getRMAllocatedUsers(user.id)
            }
            this.loadUsers(user);
           

            let q = {}
            let stq = {};
            if (user.is_superuser) {
                q = { status: 'Pending' };
                this.loadTeam();

            } else {
                q = { admin: user.admin };
                stq = { admin: user.admin };
            }
            this.setState({ query:q,activeTab: 2 })
            this.loadRecords(user, q);
            this.loadStats(user, stq)
        }
    }


    editRecord = async (id) => {
        let registerRequest = await API_POST("action/getdata", {
            root: "supportTickets",
            con: { _id: id }
        });

        this.setState({ notificationDetails: registerRequest.data[0] });

        this.categoryChange({ target: { value: registerRequest.data[0]['category'] } });

    }

    loadStats = async (user, query) => {
        if(this.state.allocatedUsers && this.state.allocatedUsers.length){
            query.admin= {$in:this.state.allocatedUsers};
        }  
        let registerRequeststats = await API_POST("action/getdata", {
            root: "supportTickets",
            con: query
        });
        let orderData = [...registerRequeststats.data];
        let pendingCount = orderData.filter((v)=>{ return v.status == 'Pending' });
        let resolvedCount = orderData.filter((v)=>{ return v.status == 'Resolved' });
        let roadmapCount = orderData.filter((v)=>{ return  v.status == 'In Roadmap' })
        let inProgress = orderData.filter((v)=>{ return  (v.status == 'In-Progress' || v.status == 'Under Development') })
        let todayQuery = orderData.filter((v)=>{ return  window.moment(v.createdOn).isSame(window.moment(), "day") })
        this.setState({
            totalCount:orderData,
            pendingCount:pendingCount,
            resolvedCount:resolvedCount,
            roadmapCount:roadmapCount,
            inProgressCount:inProgress,
            todayQuery:todayQuery
        });
    }


    loadRecords = async (user, query) => {
        if (!user || (user && !user.admin)) {
            return;
        }

       let body = {
            limit: this.state.rowsPerPage,
            skip: this.state.skip,
            sort:  { _id: -1,scheduledTime: -1 },
          };

        if(this.state.allocatedUsers && this.state.allocatedUsers.length){
            query.admin= {$in:this.state.allocatedUsers};
        }  
     
       

        let registerRequest = await API_POST("action/getdata", {
            root: "supportTickets",
            con :query,
            body
        });



        let orderData = [...registerRequest.data.data].reverse();
       
        this.setState({
            records: orderData,
            rowCount: registerRequest.data.rowCount
        });



        return orderData;
        // debugger;
        //setData(registerRequest.data || []);
        // setIsRefresh(true);

    }

    loadUsers = async (user) => {

        if (user.admin) {

            let userInfoData = await API_GET(
                `register/GetProfileData?username=${user.admin}`
            );
            debugger;
            if (userInfoData.success) {
                if (!this.state.editMode) {
                    this.setState({ notificationDetails: { phone: userInfoData.data.phone, company: userInfoData.data.company, ...this.state.notificationDetails } });
                }
            }


        }

        if (user.is_superuser) {

            let userInfo = await API_GET(`register/GetAllSubscriber?username=${user.admin}`);

            let opt = [];
            for (let i in userInfo.data) {
                opt.push({ title: userInfo.data[i].username, year: userInfo.data[i].username, data: userInfo.data[i] });
            }
            if(user.role !='owner' ){
              opt =  opt.filter((v)=> this.state.allocatedUsers.includes(v.title));
            }
           
            this.setState({ allUser: opt })

        }

    }

    handletabs = async (event, newValue) => {

        const { user, history } = this.props;
        this.setState({ activeTab: newValue });

        let q = {};
        if (newValue == 1) {
            q = {};
        }
        if (newValue == 2) {
            q = { status: 'Pending' };
        }
        if (newValue == 3) {
            q = { status: 'Resolved' };

        }
        if (newValue == 4) {
            q = { status: 'In Roadmap' };
        }
        if(newValue == 5){
            q={status: {$in:['In-Progress']}};
        }
        this.setState({
            query: q
        });
        this.loadRecords(user, q)


    }

    handleChange = async (e) => {

        let formValue = this.state.notificationDetails;

        let resolve = false;
        if(e.target.name == 'status' && e.target.value == 'Resolved'){
            resolve = true;
        }           


        if (e.target && e.target.type === "checkbox") {
            formValue[e.target.id] = e.target.checked;
        } else if (e._isAMomentObject) {
            formValue[props] = e.format();
        } else {
            formValue[e.target.name] = e.target.value === "" ? null : e.target.value;
        }

        console.log(formValue);
        this.setState({ isResolved:resolve, notificationDetails: formValue });
    };
    handleChangeCallback = async (e) => {
        let formValue = this.state.notificationDetails;
        formValue.scheduledTime = new Date(e)
        this.setState({ notificationDetails: formValue });
        
    }

    handleCancel = async (e) => {
        const { user, history } = this.props;
        history.push("/admin/support");
    };

    handleSave = async (e) => {

        this.handleSaveTicket(e);

    };

    sendNotification = async (data) => {
        //data = this.state.notificationDetails;
        const { user, history } = this.props;
        console.log("sending notification");
        try {
            let message = `Dear Subscriber,
We have noted your request about .
\n
Company -  ${data.company}
Phone - ${data.phone}
Category -  ${data.category} 
Concern about - ${data.subCategory}
Comments - ${data.comment}
`;

            if (user.is_superuser && data.adminComment) {
                message += `Techmet Comment - ${data.adminComment}`;
            }

            message += `\n
For any concern which is road-barrier to use the services, you can mention in your WhatsApp Group Or call us 1800-120-6389`;

            let subjectMessage = "Request Raised For " + data.product;

            if (this.state.editMode && user.is_superuser) {

                subjectMessage = `Request ${data.status} For ${data.product}`;
                message = `Dear Subscriber, 
Your request about ${data.product} is ${data.status}.
\n
Techmet Comment - ${data.adminComment}
Company -  ${data.company}
Phone - ${data.phone}
Category -  ${data.category} 
Concern about - ${data.subCategory}
Comments - ${data.comment}
\n
For any concern which is road-barrier to use the services, you can mention in your WhatsApp Group Or call us 1800-120-6389`;


            }

            let tou = data.admin;

            if (data.subCategory == 'New Feature required' || data.subCategory == 'Bug Reported' || data.subCategory == 'Integration Required') {
                tou = tou + ',mohit.jainonline@gmail.com,kumarshubhendu228@gmail.com';
            }
            //data.phone = '9868969659';
            let html = message;
            var mailOptions = {
                phone: data.phone,
                email: data.admin,
                bcc: "Support@techmet,in",
                type: ["email", "whatsapp"],
                from: "betyphon.crm@gmail.com",
                to: tou,
                subject: subjectMessage,
                message,
                html
            };
            //console.log(mailOptions)

            let messageforNotification = 'Query Raised for Category -' + data.category + ' Concern - ' + data.subCategory
            if (this.state.editMode && user.is_superuser) {
                this.notificationBell(data.admin, 'Query Reply - ' + data.adminComment);
            } else {
                let superAdminmessage = 'Query Raised By - ' + data.admin + ' Category -' + data.category + ' Concern - ' + data.subCategory;
                this.notificationBell('mark@gmail.com', superAdminmessage);
            }


            let registerRequest = await API_POST("commPublic/sendMessages", { ...mailOptions });

        } catch (e) {

            console.log(e);

        }

    }


    notificationBell = async (admin, message) => {

        let data = {
            type: "message",
            IsActive: true,
            read: 0,
            admin: admin,
            user: admin,
            message: message,
            link: this.state.editMode ? this.state.editId : null
        };

        let registerRequest = await API_POST("action/addRecord", {
            root: "notifications",
            body: data,
        });

    }

    handleExportExcel = async () => {
        const { user, history } = this.props;

      
        let downloadFile = await API_POST(`action/ExportExcel`, {
            querydata: this.state.query,
            body: { admin: user.admin, user: user.username, page: 'supportTickets' },
        });

        if (downloadFile.status == 200) {
            this.setState({
                notify: {
                    open: true,
                    message: "File is in process,Please check in system log section.",
                    color: "success",
                }
            });
        }
    };

    handleSaveTicket = async (e) => {

        const { user, history } = this.props;


        let formValue = this.state.notificationDetails;
        if (!this.state.editMode) {
            //formValue["admin"] =  formValue.admin ? formValue.admin : user.username
            formValue["createdOn"] = new Date();
        }
        else{
            formValue["updatedOn"] = new Date();
            formValue["updatedBy"] = user && user.username;
        }


        if(user && user.is_superuser && formValue.status != 'Resolved' && !formValue.scheduledTime){
            alert("scheduledTime is Mandatory");
            return true; 
        }

        if(formValue.status == 'Resolved' && (this.state.otpNumber != formValue.otp || !this.state.otpNumber)){
            alert("Otp Verification Failed");
            return true; 
        }           

        if(formValue.status == 'Resolved'){
            formValue['resolvedOn'] = new Date();
        }


        if (user && user.is_superuser && this.state.editMode && !this.state.notificationDetails.adminComment) {
            alert("Please add admin Comment");
            return true;
        }

        if (!formValue.user || !formValue.comment || !formValue.category || !formValue.subCategory) {
            alert("Please Fill all Mandatory Fields");
            return true;
        }

        // check for allocatedRm
        let allocatedRm = await API_GET(
            `register/GetProfileData?username=${formValue.admin}`
        );
        debugger;
        if (allocatedRm.success && allocatedRm.data.allocatedRm) {
            formValue.assignTo = allocatedRm.data.allocatedRm.email;
            // send notification to RM
        }
          // image upload
          if(this.state.selectedFiles && this.state.selectedFiles.length){
            let formData = new FormData();
            this.state.selectedFiles.forEach((file, index) => {
                formData.append(`myFile${index + 1}`, file);
            });
            formData.append('admin', user && user.admin);
            formData.append('id',this.state.editId);
            formData.append('root','supportTickets')
            let uploadRequest = await API_UPLOAD("action/uploadAssets", formData);
            if (uploadRequest && uploadRequest.success) {
                formValue.attachments = [...formValue.attachments,...uploadRequest.data];
            }
          }

        let registerRequest;
        try {
            if (this.state.editMode) {
                // send notification to RM 
                //allocatedToRM

                this.sendNotification({...formValue,phone:user && user.allocatedToRM.phone});

                delete formValue._id;
                registerRequest = await API_POST("action/update", {
                    root: "supportTickets",
                    body: formValue,
                    querydata: { _id: this.state.editId }
                });
            } else {
                registerRequest = await API_POST("action/addRecord", {
                    root: "supportTickets",
                    body: formValue,
                });
                console.log(registerRequest.data)
            }

            //console.log("ttttttt",formValue);

            if(formValue.sendNotify ){
               this.sendNotification(formValue);
            }

            // send it to manager
            let managers = getManager(user);
            let managerPhones = []
            if(managers && managers.length)  managers.map((v)=> managerPhones.push(v.phone))
            console.log("mmmmmmmmm",managers,managerPhones)
            for( let i in managerPhones){
                this.sendNotification({...formValue,phone:i});
            }

            this.setState({notificationDetails:this.state.defaultData});

            if(formValue.category == "Supports" || formValue.category == "Implementation"){
                this.setState({ pop: true })
            }

            if (registerRequest) {


                this.setState({
                    notify: {
                        open: true,
                        message: "Query Submitted.",
                        color: "success",
                    }
                });


                // history.push("/admin/support");
            }

           


        } catch (error) {
            console.log(error);
        }
    };

    handleremoveattachment = async (row) =>{

        let formValue = this.state.notificationDetails;
        let attach = formValue.attachments.filter(v => v.fileName != row.fileName)
        formValue.attachments = attach;
        this.setState({ notificationDetails: formValue });


    }

    sendOtp = async (e) =>{

        let formValue = this.state.notificationDetails;
        var val = Math.floor(1000 + Math.random() * 9000);

        try{
            let message = "BTYPHN - Dear User, Your OTP is "+val;
            var mailOptions = {
                phone: formValue.phone,
                bcc: "Support@techmet,in",
                type: ["sms", "whatsapp"],
                from: "betyphon.crm@gmail.com",
                message,
            };
            console.log(mailOptions)

            let registerRequest = await API_POST("commPublic/sendMessages", { ...mailOptions });
            
            this.setState({
                otpNumber:val,
                notify: {
                    open: true,
                    message: "Otp sent in Contact No. "+mailOptions.phone,
                    color: "success",
                }
            });

        }catch(e){

        }

    }
    render() {


        const columnsadmin = [
            {
                name: "status",
                selector: "status",
                width: "100px"

            },
            {
                name: "Created Date",
                selector: "createdOn",
                width: "100px",
                cell: (row) => (
                    <span>
                        {window.moment(row.createdOn).format('DD-MMM-YYYY hh:mm')}
                    </span>
                ),

            },
            {
                name: "Schedule Time",
                selector: "scheduledTime",
                width: "100px",
                cell: (row) => (
                    <span>
                        {row.scheduledTime ? window.moment(row.scheduledTime).format('DD-MMM-YYYY hh:mm') : <a onClick={(e)=>this.setState({pop:true})} >Schedule</a>}
                    </span>
                ),

            },
            {
                name: "Product",
                selector: "product",
                width: "100px"

            },

            {
                name: "Category",
                selector: "category",
                width: "100px"

            },
            {
                name: "Concern",
                selector: "subCategory",
                width: "200px"

            },
            {
                name: "Comment",
                selector: "comment",
                width: "300px",
                cell: (row) => (
                    <span>
                        {row.comment}  {  user.is_superuser ? <a style={{ color: "blue" }} href={'/admin/editsupport/' + row._id} > <b> edit </b></a> : ""  } 
                    </span>
                ),
            },
            {
                name: "Admin Comment",
                selector: "adminComment",
                width: "200px"

            },
            {
                name: "Company",
                selector: "company",
                width: "100px"

            },
            {
                name: "Phone",
                selector: "phone",
                width: "100px"

            },
            {
                name: "Subscriber ID",
                selector: "admin",
                width: "200px",
                cell: (row) => (
                    <span>
                        {row.externalQuery ? (<p style={{ lineBreak: 'anywhere' }}> {row.externalAdmin} </p>) : row.admin}
                    </span>
                ),

            },
            {
                name: "Assign To",
                selector: "ticketAssignedTo",
                width: "200px"

            },

            {
                name: "Attachments",
                selector: "attachments",
                width: "200px",
                cell: (row) => (
                  
            
                     <>
             <span>{(row.attachments && row.attachments.length )? (row.attachments.map((v)=>{ return (<> <a href={`http://${REACT_APP_SERVER_URL}/downloads/downloadAWSFile?fid=${row._id}&fileName=${v.fileName}&endpoint=supportTickets`} target = "_blank" > {v.fileName} </a> <br/> </>)})) : null }</span>
            </>
                   
                ),

            },
            {
                name: "Closed On",
                selector: "resolvedOn",
                width: "100px"

            },


        ]

        const columns = [
            {
                name: "Status",
                selector: "status",
                width: "180px"

            },
            {
                name: "Created Date",
                selector: "createdOn",
                width: "100px",
                cell: (row) => (
                    <span>
                        {window.moment(row.createdOn).format('DD-MMM-YYYY hh:mm')}
                    </span>
                ),

            },
            {
                name: "Last Update Date",
                selector: "updatedOn",
                width: "100px",
                cell: (row) => (
                    <span>
                        {window.moment(row.createdOn).format('DD-MMM-YYYY hh:mm')}
                    </span>
                ),

            },
            {
                name: "Schedule Time",
                selector: "scheduledTime",
                width: "100px",
                cell: (row) => (
                    <span>
                        {row.scheduledTime ? window.moment(row.scheduledTime).format('DD-MMM-YYYY hh:mm') :  <a onClick={this.setState({pop:true})} >Schedule</a>}
                    </span>
                ),

            },
            {
                name: "Updated By",
                selector: "updatedBy",
                width: "100px"

            },

            
            {
                name: "Category",
                selector: "category",
                width: "100px"

            },
            {
                name: "Concern",
                selector: "subCategory",
                width: "200px"

            },
            {
                name: "Comment",
                selector: "comment",
                width: "300px",
                cell: (row) => (
                    <span>
                        {row.comment}  {  user.is_superuser ? <a style={{ color: "blue" }} href={'/admin/editsupport/' + row._id} > <b> edit </b></a> : "" }  
                    </span>
                ),
            },

            {
                name: "Betyphon team",
                selector: "adminComment",
                width: "200px"

            },

            {
                name: "Assign To",
                selector: "ticketAssignedTo",
                width: "200px"

            },
            {
                name: "Closed On",
                selector: "resolvedOn",
                width: "100px"

            },

            


        ];
        const countPerPage = 50;
        const { classes, user } = this.props;
        const {
            records,
            notificationDetails,
            otp,
            dispositions,
            customFormFields,
            mode,
            teamuser,
            showLoader,
        } = this.state;

        return (
            <>
                <>
                    <Snackbar
                        place="right"
                        color={this.state.notify.color}
                        message={this.state.notify.message}
                        open={this.state.notify.open}
                        closeNotification={() =>
                            this.setState({
                                notify: {
                                    open: false,
                                },
                            })
                        }
                        close
                    />


                    <Dialog open={this.state.pop} fullWidth={true} maxWidth="lg">
                        <DialogTitle id="customized-dialog-title">Calendly Setup Meet   <Button color="primary" style={{ float: 'right' }}
                            size="sm"
                            onClick={this.handleClose}>
                            Close
                        </Button>
                        </DialogTitle>
                        <DialogContent>

                            <iframe src="https://calendly.com/techmet/connect-with-us-techmet" style={{ width: '100%', height: '800px' }} />

           
        </DialogContent>
        
        
      </Dialog>


                    <GridContainer>

                        <GridItem xs={12} sm={2} md={2}>

                            <Card>
                                <CardBody>

                                    <h5>Total Query <hr />  {this.state.totalCount && this.state.totalCount.length} </h5>

                                </CardBody>
                            </Card>

                        </GridItem>
                        <GridItem xs={12} sm={2} md={2}>
                            <Card>
                                <CardBody>

                                    <h5> Pending Query <hr />  {this.state.pendingCount && this.state.pendingCount.length} </h5>

                                </CardBody>
                            </Card>

                        </GridItem>
                        <GridItem xs={12} sm={2} md={2}>
                            <Card>
                                <CardBody>
                                    <h5> Roadmap Query <hr />  {this.state.roadmapCount && this.state.roadmapCount.length} </h5>

                                </CardBody>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={2} md={2}>
                            <Card>
                                <CardBody>
                                    <h5> Resolved Query <hr />  {this.state.resolvedCount && this.state.resolvedCount.length} </h5>

                                </CardBody>
                            </Card>

                        </GridItem>
                        <GridItem xs={12} sm={2} md={2}>
                            <Card>
                                <CardBody>
                                    <h5> In Progress Query <hr />  {this.state.inProgressCount && this.state.inProgressCount.length} </h5>

                                </CardBody>
                            </Card>

                        </GridItem>

                        <GridItem xs={12} sm={2} md={2}> 
<Card>
    <CardBody>
    <h5> Today Query <hr/>  {this.state.todayQuery && this.state.todayQuery.length} </h5>
   
    </CardBody>
</Card>

</GridItem>   
</GridContainer> 

                    <br />

                    {showLoader ? <Loader /> : null}
                    {user && (user.is_superuser || user.role == 'owner') ? (
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <Card>
                                    <CardHeader color="white">
                                        <h3>Raise Your support ticket</h3>
                                    </CardHeader>
                                    <CardBody>
                                        <GridContainer>

                                            <GridContainer xs={12} sm={12} md={12}>

                                                {
                                                    this.state.notificationDetails.externalQuery ? (
                                                        <>

                                                            <GridItem xs={12} sm={6} md={6}>
                                                                <CustomInput
                                                                    labelText="Select Product"
                                                                    id="product"
                                                                    formControlProps={{
                                                                        fullWidth: true,
                                                                    }}
                                                                    labelProps={{ shrink: true }}
                                                                    inputProps={{
                                                                        required: false,
                                                                        name: "product",
                                                                        type: "select",
                                                                        value: this.state.notificationDetails.product,
                                                                        onChange: this.handleChange,
                                                                        options: this.state.productsList
                                                                    }}
                                                                />
                                                            </GridItem>



                                                            <GridItem xs={12} sm={6} md={6}>
                                                                <GenerateField
                                                                    value={
                                                                        notificationDetails && notificationDetails.company
                                                                    }
                                                                    onChange={this.handleChange}
                                                                    id={"company"}
                                                                    name={"company"}
                                                                    label={"Company *"}
                                                                    type="text"


                                                                />
                                                            </GridItem>
                                                            <GridItem xs={12} sm={6} md={6}>
                                                                <GenerateField
                                                                    value={
                                                                        notificationDetails && notificationDetails.phone
                                                                    }
                                                                    onChange={this.handleChange}
                                                                    id={"phone"}
                                                                    name={"phone"}
                                                                    label={"Phone *"}
                                                                    type="text"


                                                                />
                                                            </GridItem>

                                                            <GridItem xs={12} sm={6} md={6}>
                                                                <GenerateField
                                                                    value={
                                                                        notificationDetails && notificationDetails.externalAdmin
                                                                    }
                                                                    onChange={this.handleChange}
                                                                    id={"externalAdmin"}
                                                                    name={"externalAdmin"}
                                                                    label={"External admin Id *"}
                                                                    type="text"


                                                                />
                                                            </GridItem>

                                                        </>

                                                    ) : null
                                                }





                                                <GridItem xs={12} sm={6} md={6}>
                                                    <CustomInput
                                                        labelText="Select Category"
                                                        id="category"
                                                        formControlProps={{
                                                            fullWidth: true,
                                                        }}
                                                        labelProps={{ shrink: true }}
                                                        inputProps={{
                                                            required: false,
                                                            name: "category",
                                                            type: "select",
                                                            value: this.state.notificationDetails.category,
                                                            onChange: this.categoryChange,
                                                            options: user.is_superuser ? this.state.superCategory : this.state.catoption
                                                        }}
                                                    />
                                                </GridItem>

                                                {this.state.notificationDetails.externalQuery ?
                                                    (
                                                        <GridItem xs={12} sm={6} md={6}>
                                                            <CustomInput
                                                                labelText="Sub Category (Concern)"
                                                                id="subCategory"
                                                                formControlProps={{
                                                                    fullWidth: true,
                                                                }}
                                                                labelProps={{ shrink: true }}
                                                                inputProps={{
                                                                    required: true,
                                                                    name: "subCategory",
                                                                    type: "text",
                                                                    value: this.state.notificationDetails.subCategory,
                                                                    onChange: this.handleChange,

                                                                }}
                                                            />
                                                        </GridItem>
                                                    ) :
                                                    (
                                                        <GridItem xs={12} sm={6} md={6}>
                                                            <CustomInput
                                                                labelText="Select Sub Category (Concern)"
                                                                id="subCategory"
                                                                formControlProps={{
                                                                    fullWidth: true,
                                                                }}
                                                                labelProps={{ shrink: true }}
                                                                inputProps={{
                                                                    required: false,
                                                                    name: "subCategory",
                                                                    type: "select",
                                                                    value: this.state.notificationDetails.subCategory,
                                                                    onChange: this.changesubcat,
                                                                    options: this.state.subcatoption
                                                                }}
                                                            />
                                                        </GridItem>
                                                    )



                                                }


                                            </GridContainer>

                                            <GridItem xs={12} sm={12} md={12}>

                                                {this.state.editMode && <>
                                                 
                                                    <GenerateField
                                                        value={
                                                            notificationDetails && notificationDetails.admin
                                                        }
                                                        id={"admin"}
                                                        name={"admin"}
                                                        label={"Subscriber Id *"}
                                                        type="textbox"
                                                        disabled="disabled"

                                                    />




                                                </>}

                                                {
                                                    !this.state.notificationDetails.externalQuery && user.is_superuser && !this.state.editMode && this.state.allUser && this.state.allUser.length > 0 ?

                                                        <Autocomplete
                                                            limitTags={1}
                                                            options={this.state.allUser}
                                                            name={"admin"}
                                                            getOptionLabel={(option) => option.title}
                                                            defaultValue={this.state.notificationDetails.selectAdminObj}
                                                            value={this.state.notificationDetails.selectAdminObj}
                                                            renderInput={(params) => (
                                                                <TextField {...params} label="Subscriber Id" placeholder="Subscriber Id" />
                                                            )}
                                                            id={"admin"}

                                                            onChange={(event, newValue) => {
                                                                this.changeAdmin(newValue);
                                                            }}

                                                            sx={{ width: '500px' }}
                                                        />
                                                        : null

                                                }

                                                <GenerateField
                                                    value={
                                                        notificationDetails && notificationDetails.comment
                                                    }
                                                    onChange={this.handleChange}
                                                    id={"comment"}
                                                    name={"comment"}
                                                    label={"Message *"}
                                                    type="textarea"
                                                    rows={20}
                                                    rowsMax={30}

                                                />


                                                {
                                                    user && user.is_superuser ? (
                                                        <>  



                                                            

                                                            <GenerateField
                                                                value={
                                                                    notificationDetails && notificationDetails.adminComment
                                                                }
                                                                onChange={this.handleChange}
                                                                required={this.state.commentRequired}
                                                                id={"adminComment"}
                                                                name={"adminComment"}
                                                                label={"Admin Comment *"}
                                                                type="textarea"
                                                                rows={20}
                                                                rowsMax={30}

                                                            />

                                                 <GenerateField
                                                    value={
                                                        notificationDetails && notificationDetails.scheduledTime
                                                    }
                                                    onChange={this.handleChange}
                                                    id={"scheduledTime"}
                                                    name ={"scheduledTime"}
                                                    label={"Scheduled Time "}
                                                    type="date_time"
                                               

                                                />


                                                            <CustomInput
                                                                labelText="Select Status"
                                                                id="status"
                                                                formControlProps={{
                                                                    fullWidth: true,
                                                                }}
                                                                labelProps={{ shrink: true }}
                                                                inputProps={{
                                                                    required: false,
                                                                    name: "status",
                                                                    type: "select",
                                                                    value: this.state.notificationDetails.status,
                                                                    onChange: this.handleChange,
                                                                    options: this.state.statusOption
                                                                }}
                                                            />
                                                            {this.state.editMode &&
                                                                <CallbackControl onChangeCallback={(item) => this.handleChangeCallback(item)} Title="Schedule Callback" />
                                                            }

<GenerateField
                                                                value={
                                                                    notificationDetails && notificationDetails.sendNotify
                                                                }
                                                                onChange={this.handleChange}
                                                                required={this.state.commentRequired}
                                                                id={"sendNotify"}
                                                                name={"sendNotify"}
                                                                label={"Send Notification "}
                                                                type="checkbox"
                                                              
                                                                

                                                            />

                                                    <CustomInput
                                                        labelText="Assign Ticket To"
                                                        id="ticketAssignedTo"
                                                        formControlProps={{
                                                            fullWidth: true,
                                                        }}
                                                        labelProps={{ shrink: true }}
                                                        inputProps={{
                                                            required: false,
                                                            name: "ticketAssignedTo",
                                                            type: "select",
                                                            value: this.state.notificationDetails.ticketAssignedTo,
                                                            onChange: this.handleChange,
                                                            options: this.state.ticketAssignedToOption ? this.state.ticketAssignedToOption : []
                                                        }}
                                                    />

                                                        </>

                                                    ) : null
                                                }

{this.state.editMode && <>
                                                 
                                                 <h2>Attachments </h2>
                                                 <GridItem xs={12} sm={12} md={12}>
                                                                     <input
                                                                       type="file"
                                                                      multiple
                                                                       accept=".jpeg,.png"
                                                                       onChange={(e) => {
                                                                         this.handleFileChange (e);
                                                                       }}
                                                                     />
                                                                     </GridItem>


                                                                     {
                                                                         <span>{(this.state.notificationDetails.attachments && this.state.notificationDetails.attachments.length )? (this.state.notificationDetails.attachments.map((v)=>{ return (<> <a href={`http://${REACT_APP_SERVER_URL}/downloads/downloadAWSFile?fid=${this.state.editId}&fileName=${v.fileName}&endpoint=supportTickets`} target = "_blank" > {v.fileName} </a>  
                                                                         
                                                                         
                                                                         <IconButton
        className={classes.iconButton}
        key="close"
        aria-label="Close"
        color="inherit"
        onClick={() => this.handleremoveattachment(v)}
      >
        <Close className={classes.close} />
      </IconButton>
                                                                         
                                                                          <br/> </>)})) : null }</span>
 
                                                                     }
                                                 
                                                 
                                                 
                                                                                                 </>}
                                                 


                                                { this.state.notificationDetails.status == 'Resolved' && <>
                                                
                                                <br/>
                                              
                                                <Button color="primary"
                                                    size="sm" onClick={this.sendOtp} >Send OTP</Button>
                                                <GenerateField
                                                    value={
                                                        otp
                                                    }
                                                    onChange={this.handleChange}
                                                    id={"otp"}
                                                    name ={"otp"}
                                                    label={"otp "}
                                                    type="text"
                                               

                                                />
                                                </>


                                                }




                                                        


                                            </GridItem>



                                        </GridContainer>
                                    </CardBody>

                                    <CardFooter>
                                        <center>
                                            <GridItem xs={12} sm={12} md={12}>
                                                <Button color="primary"
                                                    size="sm"
                                                    onClick={this.handleSave}>
                                                    {
                                                        this.state.editMode ? "Update" : "Submit"
                                                    }

                                                </Button>
                                                <Button color="primary"
                                                    size="sm"
                                                    onClick={this.handleCancel}>
                                                    Cancel
                                                </Button>
                                            </GridItem>
                                        </center>


                                    </CardFooter>
                                </Card>
                            </GridItem>

                        </GridContainer>


                    ) : null}
                </>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader color="white">
                                <h3>Tickets List </h3>

                            </CardHeader>


                            <GridContainer>

                                {
                                    user ? (
                                        <>

                                            <GridItem xs={12} sm={12} md={12} >
                                                <Button
                                                    style={{ "float": "right" }}
                                                    size="sm"
                                                    color="primary"
                                                    justIcon
                                                    title="Export To Excel"
                                                    onClick={() => {
                                                        this.handleExportExcel();
                                                    }}
                                                >

                                                    <GetAppOutlinedIcon />
                                                </Button>
                                            </GridItem>

                                            {
                                                this.state.ticketAssignedToOption ? <SupportFilter team={this.state.ticketAssignedToOption}  props={this.props} onfilter={this.filterUpdate} superUser={user.is_superuser} />  : null
                                            }

                                           </>

                                    ) : null

                                }

                                {

                                    user.is_superuser ? (
                                        <>
                                            <Box sx={{ bgcolor: 'background.paper', width: '100%', typography: 'body1' }} style={{ "background": "rgb(38 80 119)", "width": "100%" }}>
                                                <TabContext value={this.state.activeTab}>
                                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                                        <TabList onChange={this.handletabs} aria-label="lab API tabs example" indicatorColor="secondary">
                                                            <Tab label="All" value="1" style={{ textTransform: 'none', fontSize: '18px', color: 'white' }} />
                                                            <Tab label="Pending" value="2" style={{ textTransform: 'none', fontSize: '18px', color: 'white' }} />
                                                            <Tab label="Resolved" value="3" style={{ textTransform: 'none', fontSize: '18px', color: 'white' }} />
                                                            <Tab label="Roadmap" value="4" style={{ textTransform: 'none', fontSize: '18px', color: 'white' }} />
                                                            <Tab label="In-Progress" value="5" style={{ textTransform: 'none', fontSize: '18px', color: 'white' }} />
                                                        </TabList>
                                                    </Box>
                                                </TabContext>
                                            </Box>
                                        </>
                                    ) : null


                                }



                                <GridItem xs={12} sm={12} md={12}>
                                    <DataTable
                                        columns={user && user.is_superuser ? columnsadmin : columns}
                                        data={records || []}
                                        highlightOnHover
                                        pagination
                                        onChangePage={this.handleChangePage}
                                        paginationPerPage={ this.state.rowsPerPage}
                                        paginationServer
                                        paginationTotalRows={this.state.rowCount}
                                        paginationComponentOptions={{
                                          noRowsPerPage: true,
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>

                        </Card>
                    </GridItem>
                </GridContainer>



            </>
        );
    }
}

export default withStyles(styles)(Support);

class GenerateField extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const {
            value,
            onChange,
            id,
            options,
            label,
            keyValuePair,
            type = "select",
            disabled = false,
        } = this.props;

        return (
            <GridItem xs={12} sm={12} md={12}>
                <CustomInput
                    labelText={label}
                    id
                    labelProps={{ shrink: true }}
                    formControlProps={{
                        fullWidth: true,
                        multiline: true,
                    }}
                    customOptions={options}
                    inputProps={{
                        required: false,
                        value,
                        defaultValue: value,
                        checked: value,
                        name: id,
                        id: id,
                        type: type,
                        onChange,
                        disabled,
                        options: keyValuePair,
                    }}
                />
            </GridItem>
        );
    }
}
