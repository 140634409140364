import React from "react";
import PropTypes from "prop-types";
// react plugin for creating charts

// import { Chart } from "react-google-charts";
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons


// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import _ from "underscore";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import { API_GET } from "../../services/api.service";
import Box from "./Box";
import LRChart from "./LRChart";
import CRChart from "./CRChart";
import AWChart from "./AWChart";
import {Link} from "react-router-dom";
import InfoBox from "../../components/InfoIcon/InfoIcon";

// import InfoIcon from "@material-ui/icons/Info";
// import Typography from "@material-ui/core/Typography";


class ProspectWise extends React.Component {

  state = {
    value: 0,
    aggData: null,

  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = (index) => {
    this.setState({ value: index });
  };

  componentWillMount() {
    const { user } = this.props;
    if (user) {
      this.loadCampaign();
    }
  }

  loadCampaign = async () => {
    const { user } = this.props;
    let registerRequest;
    try {

      if (user && user.admin) {

        registerRequest = await API_GET(
          "action/DashboardData?admin=" + user.admin + "&username=" + user.username + "&role=" + user.role + '&sdt=' + window.moment(this.state.startDate).format('YYYY-MM-DDTHH:mm') + '&edt=' + window.moment(this.state.endDate).format('YYYY-MM-DDTHH:mm')
        );
        if (registerRequest && registerRequest.status == 200) {

          if (registerRequest.data) {
            this.setState({ aggData: registerRequest.data });
            localStorage.setItem("dashboardData", JSON.stringify(registerRequest.data))
          }


        }
      }
    } catch (error) {
      console.log(error);
    }
  };




  render() {
    const { classes, user } = this.props;
    console.log("ProspectWise user", user)
    const { aggData } = this.state;
    const { startDate, endDate, isReload } = this.props;
    console.log("isReload", isReload);
    const freshQuery = {
      $and: [
        { open: true  },
        { IsActive: true },
        { disposition: { $exists: false } }
      ]
    }
    const newQuery = {
      IsActive: true,
      createdOn: {
        $gte: new Date(startDate),
        $lt: new Date(endDate),
      }
    }
    const activeCampaign = {
      IsActive: true      
    }





    const activeQuery = {
      $and: [
        {
          IsActive: true
        },
        {
           open: true 
        },
        {
          $or: [{ callback: { $ne: null } }, { appointment: { $ne: null } }]
        }
      ]
    }


    const totalCalls = {
      createdOn: {
        $gte: new Date(startDate),
        $lt: new Date(endDate),
      }
    }


    const followupQuery = {

      callback: { $gte: new Date(startDate), $lte: new Date(endDate) }

    }

    const overDueQuery = {
      $and: [
        {
          IsActive: true
        },
        {
           open: true
        }
      ],
      callback: { $lt: new Date() }
    }

    const totalProspects = {
      LastCalled: {
        $gte: new Date(startDate),
        $lt: new Date(endDate),
      },
      IsActive:true
    }

    const freshProspects = {
      LastCalled: {
        $gte: new Date(startDate),
        $lt: new Date(endDate),
      },
      TotalCalls: 1
    }

    const totalCallDetails = {
      startDate: {
        $gte: new Date(startDate),
        $lt: new Date(endDate),
      }
    }
    const totalCallRecording = {
      endDate: {
        $gte: new Date(startDate),
        $lt: new Date(endDate),
      }
    }

    if (user && ["field_exc", "agent"].indexOf(user.role) > -1) {
      newQuery['assignTo'] = user && user.username
      totalProspects['assignTo'] = user && user.username
      freshProspects['assignTo'] = user && user.username
      totalCalls['caller'] = user && user.username
      followupQuery['caller'] = user && user.username
      overDueQuery['assignTo'] = user && user.username
      totalCallDetails['caller'] = user && user.username
      totalCallRecording['user'] = user && user.username
    }


    return (
      <div>
        <GridContainer style={{ display: 'flex', justifyContent: 'flex-end'}}>
          <GridItem style={{marginTop: "10px", marginBottom: "-20px", marginRight: "10px", zIndex: "1" }}>
            <InfoBox name={'Dashboard.ProspectWise'} />
          </GridItem>
        </GridContainer>

        <GridContainer>
          {["manager", "owner"].indexOf(user && user.role) > -1 && <GridItem xs={12} sm={6} md={3}>
            <Box
              label={"Active Campaigns"}
              icon={"fa fa-bullhorn fa-5x"}
              root="campaigns"
              query={activeCampaign}
              user={user}
              isReload={isReload}
              url={"/admin/campaigns"}
            />

          </GridItem>}

          {["manager", "owner"].indexOf(user && user.role) > -1 && <GridItem xs={12} sm={6} md={3}>
            <Card>
              <CardHeader color="success" stats icon>
                <CardIcon color="success">
                  <i className="fa fa-users fa-5x" />
                </CardIcon>
                <p className={classes.cardCategory}>Assigned Members</p>
                <h3 className={classes.cardTitle}>{aggData && aggData.team}</h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>

                  <Link to={'/admin/myteam'}>
                    View Details
                  </Link>
                </div>
              </CardFooter>
            </Card>
          </GridItem>}
          {["manager", "owner"].indexOf(user && user.role) > -1 && <GridItem xs={12} sm={6} md={3}>
            <Box
              label={"Fresh Prospects"}
              icon={"fa fa-address-card fa-5x"}
              root="prospects"
              query={freshQuery}
              user={user}
              isReload={isReload}
              url={'/admin/ListProspects?IsFreshLeads=true'}
            />

          </GridItem>}
          {["manager", "owner"].indexOf(user && user.role) > -1 && <GridItem xs={12} sm={6} md={3}>
            <Box
              label={"Total Follow up"}
              icon={"fa fa-phone fa-4x"}
              root="prospects"
              query={activeQuery}
              user={user}
              isReload={isReload}
              url={'/admin/ListProspects?query='+btoa(JSON.stringify(activeQuery))}



            />

          </GridItem>}
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={4} md={2}>
            <Box
              label={"New Prospects"}
              icon={"fa fa-user-plus fa-4x"}
              root="prospects"
              query={newQuery}
              user={user}
              small={true}
              isReload={isReload}
              url={'/admin/ListProspects?query='+btoa(JSON.stringify({...newQuery}))}
            />

          </GridItem>
          <GridItem xs={12} sm={4} md={2}>

            <Box
              label={"Today Calls"}
              icon={"fa fa-phone fa-4x"}
              root="CallHistory"
              color="success"
              query={{ ...totalCalls }}
              user={user}
              small={true}
              isReload={isReload}
              url={'/admin/CustActivity?tab=0&Search='+btoa(JSON.stringify({...totalCalls}))}
            />

          </GridItem>

          <GridItem xs={12} sm={4} md={2}>
            <Box
              label={"Total Prospects"}
              icon={"fa fa-check-circle fa-4x"}
              root="prospects"
              color="danger"
              query={{ ...totalProspects }}
              user={user}
              small={true}
              isReload={isReload}

              url={'/admin/ListProspects?query='+btoa(JSON.stringify({...totalProspects}))}
            />


          </GridItem>
          <GridItem xs={12} sm={4} md={2}>
            <Box
              label={"Fresh Prospects"}
              icon={"fa fa-check-circle-o fa-4x"}
              root="prospects"
              color="danger"
              query={{ ...freshProspects }}
              user={user}
              small={true}
              isReload={isReload}
              url={'/admin/ListProspects?query='+btoa(JSON.stringify({...freshProspects}))}
            />

          </GridItem>

          <GridItem xs={12} sm={4} md={2}>
            <Box
                label={"Followup Calls"}
                icon={"fa fa-calendar fa-4x"}
                root="prospects"
                color="warning"
                query={{ ...followupQuery }}
                user={user}
                small={true}
                isReload={isReload}
                url={'/admin/ListProspects?query='+btoa(JSON.stringify(followupQuery))}
            />

          </GridItem>
          <GridItem xs={12} sm={4} md={2}>
            <Box
                label={"Overdue Followups"}
                icon={"fa fa-calendar fa-4x"}
                root="prospects"
                color="danger"
                query={{ ...overDueQuery }}
                user={user}
                small={true}
                isReload={isReload}

                url={'/admin/ListProspects?query='+btoa(JSON.stringify({...overDueQuery}))}
            />

          </GridItem>

        </GridContainer>



        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <LRChart
              query={{ startDate: new Date(startDate), endDate: new Date(endDate) }}
              user={user}
            />

          </GridItem>

          <GridItem xs={12} sm={12} md={6}>
            <CRChart
              query={{ startDate: new Date(startDate), endDate: new Date(endDate) }}
              user={user}
            />

          </GridItem>
          <GridItem xs={12} sm={12} md={12}>

            <AWChart
              query={{ startDate: new Date(startDate), endDate: new Date(endDate) }}
              user={user}
            />

          </GridItem>

        </GridContainer>

      </div>
    );
  }
}

ProspectWise.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(dashboardStyle)(ProspectWise);
