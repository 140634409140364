export const getQueryParams = (params, url) => {
  let href = url;
  //this expression is to get the query strings
  let reg = new RegExp('[?&]' + params + '=([^&#]*)', 'i');
  let queryString = reg.exec(href);
  return queryString ? queryString[1] : null;
};

export const maskNumber = (phone) => {
  var maskedNumber = phone.replace(/\d(?=\d{2})/g, "X");
  return maskedNumber;
};

export const getToken = () => {
  //return localStorage.getItem("token");
  return getCookie("token");
}

export const setToken = (token) => {
  debugger
  return setCookie("token", token, 1)

  //return localStorage.setItem("token", token);
}

export const removeToken = () => {
  //return localStorage.removeItem("token");
  return setCookie("token", "", -1);
}

export const copyToClipboard = (str) => {
  const el = document.createElement('textarea');
  el.value = str;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

export const getProduct = () => {
  return 1;
}

export function setCookie(cname, cvalue, exdays,object=false) {
  var d = new Date();
  if(object) cvalue = JSON.stringify(cvalue);
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  var InExpires = "domain=.betyphon.in;expires=" + d.toUTCString();
  var ComExpires = "domain=.betyphon.com;expires=" + d.toUTCString();
  var LocalExpires = "domain=localhost;expires=" + d.toUTCString();
  console.log("secccccccccc",cvalue)

  document.cookie = cname + "=" + cvalue + ";" + InExpires + ";path=/";
  document.cookie = cname + "=" + cvalue + ";" + ComExpires + ";path=/";
  document.cookie = cname + "=" + cvalue + ";" + LocalExpires + ";path=/";
}



export function getCookie(cname,object=false) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);

  var ca = decodedCookie.split(';');
  console.log("coooo",ca)
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

