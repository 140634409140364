import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import { Link } from "react-router-dom";
import { API_GET, API_POST } from "../../services/api.service";
import _ from "underscore";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import UserDropdown from "../common/UserDropdown";


import { Chart } from "react-google-charts";
import GenerateField from "../common/GenerateField";
import Box from "../Dashboard/Box";
import InfoBox from "../../components/InfoIcon/InfoIcon";
import { getConfigurationByName } from "../common/helper";






function CallTracker(props) {
  const { user, startDate, endDate, isReload , configSettings} = props;
  const [data, setData] = useState([]);
  const [rawData, setRawData] = useState([]);
  const [reportData, setreportData] = useState([]);
  const [trackerData, setTrackerData] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedCallType, setSelectedCallType] = useState('business');
  const [dynamicHeight, setDynamicHeight] = useState(100);
  const [dynamicHeightTracker, setDynamicHeightTracker] = useState(100);
  const [total, setTotal] = useState({});

  

  let totalCallRecording = {
    endDate: {
      $gte: new Date(startDate),
      $lt: new Date(endDate),
    }
  }


  Date.prototype.addHours = function(h) {
    this.setTime(this.getTime() + (h*60*60*1000));
    return this;
  }

  const loadWorkloadData = async () => {
    setData([]);
    setRawData([]);
    setreportData([]);
    setTrackerData([])
    let query;
  
    query = {
      startDate: {
        $gte: new Date(startDate).setHours(0, 0, 0, 0),
        $lt: new Date(endDate).setHours(23, 59, 59, 0),
      },
      admin: user && user.admin
    };
    //debugger;
    if (user && ["field_exc", "agent"].indexOf(user.role) > -1) {
      query = { ...query, caller: user && user.username };
    }

    let body = {
      limit: 0,
      skip: 0,
      sort: { startDate: 1 },
    };
    let res = await API_POST("action/getPaginationDataActivity", {
      root: "CallDetails",
      querydata: query,
      body

    });

    // let res = await API_GET("action/AggregateReport", null, {
    //   admin: user && user.admin
    // });
    //debugger;

    if (res.data && res.data.activityData.length > 0) {

      let data = res.data.activityData;
      //debugger;

      setRawData(data || []);

    }

    let trackerquery = {};
    let dateObj = window.moment(new Date());
    trackerquery =  {date:dateObj.format('YYYY-MM-DD'),admin: user && user.admin };

    if(user.role != 'owner' ){
      trackerquery.user = user.username;
    }

    let trackerres = await API_POST("action/getdata", {
      root: "timeTraker",
      con: trackerquery,
    });

    if (trackerres.data && trackerres.data.length > 0) {


      console.log("tracking data",trackerres.data)

     let ttempdata = [
        [
          { type: "string", id: "Position" },
          { type: "string", id: "Name" },
          { type: "date", id: "Start" },
          { type: "date", id: "End" },
        ]
      ];

      let action = [];
      trackerres.data.map( (d)=>{
d.history.sort((a, b) => new Date(a.actionOn) - new Date(b.actionOn));

        d.history.map((p,k)=>{
          let nextAction = window.moment(p.actionOn);
          let currentAction =  window.moment(p.actionOn);

          if(d.history[k+1] && d.history[k+1].actionOn){
            nextAction = window.moment(d.history[k+1].actionOn);
           
          }else{
            nextAction = window.moment();
          }
          // console.log("action",p.lastAction,nextAction)
if(currentAction.isBefore(nextAction)){
          ttempdata.push([d.user,p.status,currentAction,nextAction]);
//action.push(nextAction)
          }
        })
      })


      //console.log("tttttttttttt",ttempdata)

      if(trackerres.data.length){
        setDynamicHeightTracker(100 * trackerres.data.length);
        setTrackerData(ttempdata);
      }

    }
    //setChartOption(_chartOption);
  };


  useEffect(() => {
    if (user && user.admin) {
      loadWorkloadData();
    }
  }, [user, isReload , selectedUsers]);

  useEffect(() => {
    CreateReport();
  }, [rawData, selectedUsers, selectedCallType,trackerData]);


  const CreateReport = () => {
    let data = rawData;
    let users = selectedUsers;
    let reportData = [];
    let graphData = [];

    if (data && data.length == 0) {
      return [];
    }


    if (selectedCallType == 'business') {
      data = _.filter(data, function (row) { return row.pid != undefined; });
    }


    graphData.push([
      { type: "string", id: "Caller" },
      { type: "string", id: "Phone" },
      { type: 'string', role: 'style' },
      { type: "date", id: "Start" },
      { type: "date", id: "End" }

    ])

    let graphDataGraph = data;
    if (selectedUsers.length > 0) {
      graphDataGraph = _.filter(data, function (row) { return selectedUsers.indexOf(row.caller) > -1 });
    }
    graphDataGraph = graphDataGraph.map((v)=>{ v.duration = parseInt(v.duration); v.talktime = parseInt(v.talktime); return v; });
    
    graphDataGraph.forEach(element => {
      let obj = [element.caller, element.phone,
      element.pid ? '#4285f4' : '#db4437',
      new Date(element.startDate),
      new Date(element.endDate)

      ]
      if(element.caller){
        graphData.push(obj);
      }
  
    });

    console.log("graphdata",graphData)

    setData(graphData || []);

    {

      let heightData = _.groupBy(graphDataGraph, function (num) { return num.caller; });
      let height = 50;
      height = height + (80 * Object.keys(heightData).length);

      setDynamicHeight(height);
    }


    for (let index = 0; index < 24; index++) {
      let json = {
        hour: "Time - " + index + (index >= 12 ? ' pm' : ' am'),
        totalCalls: 0,
        ConnectedCalls: 0,
        OutgoingCalls: 0,
        IncomingCalls: 0,
        MissedCalls: 0,
        Talktime: 0
      }


      let hourlyData;
      hourlyData = _.filter(data, function (row) { return (new Date(row.startDate)).getHours() == index; });
      if (hourlyData) {

        //totalCalls
        let totalCalls = hourlyData;
        if (selectedUsers.length > 0) {
          totalCalls = _.filter(totalCalls, function (row) { return selectedUsers.indexOf(row.caller) > -1; });
        }
        // if (selectedCallType == 'business') {
        //   totalCalls = _.filter(totalCalls, function (row) { return row.pid != undefined; });
        // }
        json.totalCalls = totalCalls.length;

        //ConnectedCalls
        let ConnectedCalls = _.filter(hourlyData, function (row) { return parseInt(row.duration) > 0; });
        if (selectedUsers.length > 0) {
          ConnectedCalls = _.filter(ConnectedCalls, function (row) { return selectedUsers.indexOf(row.caller) > -1 });
        }
        // if (selectedCallType == 'business') {
        //   ConnectedCalls = _.filter(ConnectedCalls, function (row) { return row.pid != undefined; });
        // }
        json.ConnectedCalls = ConnectedCalls.length;


        //OutgoingCalls
        let OutgoingCalls = _.filter(hourlyData, function (row) { return (row.type == 'OUTGOING' || row.type == 'Outgoing' || row.type == 'default' ) ;  });
        if (selectedUsers.length > 0) {
          OutgoingCalls = _.filter(OutgoingCalls, function (row) { return selectedUsers.indexOf(row.caller) > -1 });
        }
        // if (selectedCallType == 'business') {
        //   OutgoingCalls = _.filter(OutgoingCalls, function (row) { return row.pid != undefined; });
        // }
        json.OutgoingCalls = OutgoingCalls.length;

        //IncomingCalls
        let IncomingCalls = _.filter(hourlyData, function (row) { return (row.type == 'INCOMING' || row.type == 'Incoming'); });
        if (selectedUsers.length > 0) {
          IncomingCalls = _.filter(IncomingCalls, function (row) { return selectedUsers.indexOf(row.caller) > -1 });
        }
        // if (selectedCallType == 'business') {
        //   IncomingCalls = _.filter(IncomingCalls, function (row) { return row.pid != undefined; });
        // }
        json.IncomingCalls = IncomingCalls.length;

        //MissedCalls
        let MissedCalls = _.filter(hourlyData, function (row) { return (row.type == 'MISSED' || row.type == 'Missed' || row.type == 'REJECTED'); });
        if (selectedUsers.length > 0) {
          MissedCalls = _.filter(MissedCalls, function (row) { return selectedUsers.indexOf(row.caller) > -1 });
        }
        // if (selectedCallType == 'business') {
        //   MissedCalls = _.filter(MissedCalls, function (row) { return row.pid != undefined; });
        // }
        json.MissedCalls = MissedCalls.length;

        //Talktime

        let Talktime = _.reduce(totalCalls, function (num, row) {
          return num + parseInt(row.duration)
        }, 0);


        json.Talktime = Talktime;
        if (json.Talktime > 0) {
          reportData.push(json)
        }
      }
      //debugger;
      if (reportData.length > 0) {
        let totalJson = {
          totalCalls: 0,
          ConnectedCalls: 0,
          OutgoingCalls: 0,
          IncomingCalls: 0,
          MissedCalls: 0,
          Talktime: 0
        }

        for (let index = 0; index < reportData.length; index++) {
          const element = reportData[index];
          totalJson.totalCalls = totalJson.totalCalls + element.totalCalls;
          totalJson.ConnectedCalls = totalJson.ConnectedCalls + element.ConnectedCalls;
          totalJson.OutgoingCalls = totalJson.OutgoingCalls + element.OutgoingCalls;
          totalJson.IncomingCalls = totalJson.IncomingCalls + element.IncomingCalls;
          totalJson.MissedCalls = totalJson.MissedCalls + element.MissedCalls;
          totalJson.Talktime = parseInt(totalJson.Talktime) + parseInt(element.Talktime);
        }

        try{
          totalJson.Talktime=new Date((totalJson.Talktime ? totalJson.Talktime : 0) * 1000)
              .toISOString().substr(11, 8);
        }catch (e) {
          totalJson.Talktime=0;
        }

        console.log(totalJson);
        setTotal(totalJson);
      }

    }

    setreportData(reportData)
    return reportData;
  }

  const handleChangeUserDDL = (users) => {
    console.log("handleChangeUserDDL", users);
    setSelectedUsers(users);
    //CreateReport(data, users)
  }
  const onChangeCallType = (e) => {
    console.log("onChangeCallType", e.target.value);
    setSelectedCallType(e.target.value);
    //CreateReport(data, users)
  }

  const fancyTimeFormat=(duration)=>
  {
    // Hours, minutes and seconds
    var hrs = ~~(duration / 3600);
    var mins = ~~((duration % 3600) / 60);
    var secs = ~~duration % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    var ret = "";

    if (hrs > 0) {
      ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }

    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
    return ret;
  }


  const columnsTracker = [
    {
      name: "User",
      cell: (row) => (
        <span>{row.username}</span>
      ),
    },
    {
      name: "Login Time",
      cell: (row) => (
        <span>
          {row.firstLoginTime && window.moment(row.firstLoginTime).add(6.5,'hours').format('YYYY-MM-DD hh:mm:ss')  }
        </span>
      ),
    },
    {
      name: "Hour worked",
      selector: "loginTime",
      cell: (row) => (
        <span>
          {fancyTimeFormat(row.loginTime)  }
        </span>
      ),
    },
    {
      name: "Break Time",
      selector: "breakTime",
      cell: (row) => (
        <span>
          {fancyTimeFormat(row.breakTime)  }
        </span>
      ),
    },
    {
      name: "Logout Time",  
      cell: (row) => (
        <span>
          {row.lastLogout && window.moment(row.lastLogout).add(6.5,'hours').format('YYYY-MM-DD hh:mm:ss')  }
        </span>
      ),
    },
  ]

  const columns = [
    {
      name: "Hour",
      selector: "hour",
    },
    {
      name: "Total Calls",
      selector: "totalCalls",
    },
    {
      name: "Connected Calls",
      selector: "ConnectedCalls",
    },
    {
      name: "Outgoing Calls",
      selector: "OutgoingCalls",
    },
    {
      name: "Incoming Calls",
      selector: "IncomingCalls",
    },
    {
      name: "Missed Calls",
      selector: "MissedCalls",
    },
    {
      name: "Talktime",
      selector: "Talktime",
      cell: (row) => (
        <span>
          {fancyTimeFormat(row.Talktime)  }
        </span>
      ),
    },
  ]

  if (user && ["field_exc", "agent"].indexOf(user.role) > -1) {
    totalCallRecording['user'] = user && user.username;
  }


  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={4} md={4}>
          <h3>
            Call Tracking Report
          </h3>
          <GridItem  style={{float: "right", marginTop:"-40px"}}>
            <InfoBox name={'Dashboard.CallDetailWise'}/>
          </GridItem>

        </GridItem>
        {
          user && user.role !="agent" &&  <GridItem xs={12} sm={4} md={4}>
            <UserDropdown user={user} id="users" label="My Team" handleChange={handleChangeUserDDL} ></UserDropdown>
          </GridItem>

        }

{
          user && (user.role !="agent" || (user.role =="agent" && !getConfigurationByName(user,"hidePersonalCallDetail"))) &&
        <GridItem xs={12} sm={4} md={4}>
          <GenerateField

            onChange={onChangeCallType}
            value={selectedCallType}
            id={'CallType'}
            name={'CallType'}
            label={'CallType'}
            type="select"
            keyValuePair={[{ key: 'all', value: 'ALL' }, { key: 'business', value: 'Business' }]}
          />

        </GridItem>
}

      </GridContainer>

      <GridContainer>
        <GridItem xs={12} sm={4} md={2}>

          <Box
            label={"Total Calls"}
            icon={"fa fa-compress fa-4x"}
            color="success"
            propData={total.totalCalls}
            user={user}
            small={true}
            isReload={isReload}
          />

        </GridItem>
        <GridItem xs={12} sm={4} md={2}>

          <Box
            label={"Total Incoming"}
            icon={"fa fa-arrow-down fa-4x"}
            color="primary"
            propData={total.IncomingCalls}
            user={user}
            small={true}
            isReload={isReload}
          />

        </GridItem>
        <GridItem xs={12} sm={4} md={2}>

          <Box
            label={"Total Outgoing"}
            icon={"fa fa-arrow-up fa-4x"}
            color="success"
            propData={total.OutgoingCalls}
            user={user}
            small={true}
            isReload={isReload}
          />

        </GridItem>
        <GridItem xs={12} sm={4} md={2}>

          <Box
            label={"Total Missed"}
            icon={"fa fa-retweet fa-4x"}

            color="danger"
            propData={total.MissedCalls}
            user={user}
            small={true}
            isReload={isReload}
          />

        </GridItem>
        <GridItem xs={12} sm={4} md={2}>

          <Box
            label={"Total TalkTime"}
            icon={"fa fa-volume-control-phone fa-4x"}
            color="success"
            propData={total.Talktime}
            user={user}
            small={true}
            isReload={isReload}
          />

        </GridItem>
        <GridItem xs={12} sm={4} md={2}>
          <Box
            label={"Total Recordings"}
            icon={"fa fa-microphone fa-4x"}
            root="CallRecording"
            color="success"
            query={totalCallRecording}
            user={user}
            small={true}
            isReload={isReload}
          />


        </GridItem>
      </GridContainer>
      <GridContainer>


{ 

      configSettings && configSettings.enableTimeTracking &&       <GridItem xs={12} sm={12} md={12}>
          <Card chart>
            <CardHeader color="primary">
            { trackerData && trackerData.length > 0 &&
               <Chart
                  chartType="Timeline"
                  data={trackerData}
                  width="100%"
                  height={dynamicHeightTracker}
                  options={{
                    timeline: { colorByRowLabel: false,groupByRowLabel:true },
                    allowHtml: true,
avoidOverlappingGridLines: false,
                    hAxis: {
                      format: 'HH:MM'
                    },
          
                    height:dynamicHeightTracker,                   
                  }}
                />
             }
            </CardHeader>
            <CardBody>
              <i>
                Agent Working Time tracking report
              </i>

            </CardBody>
          </Card>



        </GridItem>

     }



        <GridItem xs={12} sm={12} md={12}>
          <Card chart>
            <CardHeader color="primary">
              <DataTable
                columns={columns}
                data={reportData || []}
                highlightOnHover
                dense
              />
            </CardHeader>
            <CardBody>
              <i>
                Agent call tracking Hourly report
              </i>

            </CardBody>
            <CardFooter chart />
          </Card>



        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card chart>
            <CardHeader color="primary">
              {data && data.length > 0 &&

                <Chart
                  chartType="Timeline"
                  data={data}
                  width="100%"
                  height={dynamicHeight}
                  options={{
                    is3D: true,
                    timeline: {
                      colorByRowLabel: true,
                    },
                  }}
                />
              }
            </CardHeader>
            <CardBody>
              {data.length == 0 && <i>No Agent activity data yet.</i>}
            </CardBody>
            <CardFooter chart />
          </Card>

        </GridItem>

      </GridContainer>
    </>
  );
}

export default CallTracker;


