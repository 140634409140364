import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  filterGrid: {
    marginBottom: theme.spacing(2)
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2)
  }
}));

const ReportFilters = ({ data,columns, onFilterChange }) => {
    const classes = useStyles();
    const [filters, setFilters] = useState({});
    const [filterConfig, setFilterConfig] = useState([]);

    useEffect(() => {
        if (data && data.length > 0 && columns && columns.length > 0) {
            const config = columns.map(column => {
                const sampleValue = data[0][column.id];
                let type = 'text';
                if (typeof sampleValue === 'number') type = 'number';
                if (sampleValue instanceof Date) type = 'date';
                
                return {
                    name: column.id,
                    label: column.label,
                    type: type,
                    options: type === 'text' ? [ ...new Set(data.map(item => item[column.id]))] : null
                };
            });
            setFilterConfig(config);
            
            const initialFilters = {};
            config.forEach(field => {
                initialFilters[field.name] = '';
            });
            setFilters(initialFilters);
        }
    }, [data]);

    const handleInputChange = (e) => {
        setFilters({ ...filters, [e.target.name]: e.target.value });
    };

    const handleApplyFilters = () => {
        onFilterChange(filters);
    };

    const handleClearFilters = () => {
        const clearedFilters = {};
        Object.keys(filters).forEach(key => {
            clearedFilters[key] = '';
        });
        setFilters(clearedFilters);
        onFilterChange({});
    };

    const renderFilterField = (field) => {
        const commonProps = {
            labelText: field.label,
            id: field.name,
            formControlProps: { fullWidth: true },
            inputProps: {
                name: field.name,
                value: filters[field.name] || '',
                type: 'select',
                onChange: handleInputChange
            }
        };

        if (field.options && field.options.length > 0) {
            return (
                <CustomInput
                    {...commonProps}
                    customOptions={[
                        { key: '', value: 'All' },
                        ...field.options.map(option => ({ key: option, value: option }))
                    ]}
                />
            );
        }

        return (
            <CustomInput
                {...commonProps}
                inputProps={{
                    ...commonProps.inputProps,
                    type: field.type
                }}
            />
        );
    };

    return (
        <Card>
            {/* <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Report Filters</h4>
                <p className={classes.cardCategoryWhite}>
                    Customize your report view
                </p>
            </CardHeader> */}
            <CardBody>
                <Grid container spacing={2} className={classes.filterGrid}>
                    {filterConfig.map(field => (
                        <Grid item xs={12} sm={6} md={4} key={field.name}>
                            {renderFilterField(field)}
                        </Grid>
                    ))}
                </Grid>
                <div className={classes.buttonContainer}>
                    <Button color="primary" onClick={handleApplyFilters}>
                        Apply Filters
                    </Button>
                    <Button color="secondary" onClick={handleClearFilters} style={{ marginLeft: '10px' }}>
                        Clear Filters
                    </Button>
                </div>
            </CardBody>
        </Card>
    );
};

export default ReportFilters;
