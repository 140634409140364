import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import DataTableMobile from "components/DataTableMobile/DataTableMobile.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import { Link } from "react-router-dom";
import { API_POST } from "../../services/api.service";
import _ from "underscore";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
// import Filters from "./Filter";
import Button from "components/CustomButtons/Button.jsx";
import Filters from "../Schedule/Filters";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
//import { Snackbar } from "@material-ui/core";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Loader from "../../components/Loader/Loader";
import InfoBox from "../../components/InfoIcon/InfoIcon";
import { getManagerTeam } from "../common/helper";

// import InfoIcon from "@material-ui/icons/Info";
// import Typography from "@material-ui/core/Typography";


const columns = [
  {
    name: "Prospect",
    //inHeader: true,
    cell: (row) => <>{customCell(row)}</>,
    //  style:(row) => row.callDialed &&  "background-color:red"
  },
  {
    name: "Disposition",
    cell: (row) => (
      <span>
        {_.pluck(row.dispositionArray, "disposition_value").join(" > ")}
      </span>
    ),
  },
  {
    name: "Scheduled Time",
    cell: (row) => (
      <span>
        {row.callback && window.moment(row.callback).format("DD-MMM-YYYY HH:mm")}
      </span>
    ),
  },
  {
    name: "Campaign",
    cell: (row) => (
      <span>
        {row.CampainData && row.CampainData.length > 0
          ? row.CampainData[0].name
          : "-"}
      </span>
    ),
  },
  {
    name: "Remarks",
    selector: row=>row.remarks,
  },
  {
    name: "CallDialed",
    cell: (row) => <span>{customcallDialedCell(row)}</span>,
    //  style:(row) => row.callDialed &&  "background-color:red"
  },
  {
    name: "Last Called Time",
    cell: (row) => (
      <span>
        {row.createdOn &&
          window.moment(row.createdOn).format("DD-MMM-YYYY HH:mm")}
      </span>
    ),
  },
  {
    name: "Last Caller",
    selector: row=>row.caller,
  }

];

const handleURL = () => {
  window.localStorage.setItem("LastURL", window.location.href);
};

const customCell = (value) => {

  let val = "";
  if (value.firstName && value.lastName) {
    val = value.firstName + " " + value.lastName;
  } else if (value.firstName && value.firstName != ' ') {
    val = value.firstName;
  } else {
    val = value.phone;
  }
  return (
    <Link onClick={handleURL} to={"/admin/ViewProspect/" + value.pid}>
      {val}
    </Link>
  );
};

const customNameCell = (value) => {
  let val = value.phone;

  return (
    <span>
      {val}
    </span>
  );
};
//callDialed
const customcallDialedCell = (value) => {
  let val = "";
  if (value.callDialed) {
    val = "Yes";
  } else {
    val = "No";
  }
  return (
    <span>
      {val}
    </span>
  );
};
const checkAudio = (value) => {
  let val = "";
  if (value.S3URL == undefined || value.S3URL == "") {
    return (<span></span>);
  }
  return (
    <audio controls>
      <source src={value.S3URL} type="audio/mp3" />
    </audio>
  );
};

const tempcheckprospect = [];

const checkprospect = [];


function Activity(props) {
  const { user } = props;
  const [data, setData] = useState({});
  const [page, setPage] = useState(1);
  const countPerPage = 10;
  const [Query, setQuery] = useState("");
  const [skip, setSkip] = React.useState(0);
  const [startDate, setStartDate] = useState(new Date().setHours(0, 0, 0, 0));
  const [endDate, setEndDate] = useState(new Date().setHours(23, 59, 59, 0));
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedCallType, setSelectedCallType] = useState(null);
  const [SelectedCampaign, setSelectedCampaign] = useState([]);
  const [mobileNo, setMobileNo] = useState([]);
  const [open, setOpen] = useState(false);
  const [IsRefresh, setIsRefresh] = useState(false);
  const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 760);
  const [notify, setNotify] = useState({
    open: false,
    message: null,
    color: "success",
  });

  const handleChangePage = (newPage) => {
    setPage(newPage);
    setSkip((newPage - 1) * countPerPage);
  };

  const pageLoadquery = async () => {
    debugger;
    let query;
    const urlParam = new URLSearchParams(window.location.search);
    let search = urlParam.get("Search");

    if (!search) {
      let data = {
        SelectedCampaign: SelectedCampaign,
        selectedUser: selectedUser,
      };

      query = await handlequery(data, query);
      query = {
        ...query,
        createdOn: {
          $gte: new Date(startDate),
          $lt: new Date(endDate),
        },
      };
    } else {
      if (search) {
        search = JSON.parse(atob(search));
      }

      query = { ...search, admin: user && user.admin };
      if (user && ["field_exc", "agent"].indexOf(user.role) > -1) {
        query = { ...query, caller: user && user.username };
      }
    }

    return query;
  };

  const handlequery = async (data, query) => {
    debugger;
    query = {
      $and: [
        { admin: user && user.admin },
        {
          $or: [{ IsActive: { $exists: false } }, { IsActive: true }],
        },
      ],
    };

    if (data.mobileNo && data.mobileNo.length > 0) {
      query = { ...query, phone: { $regex: '^'+data.mobileNo } };
    }

    if (data.SelectedCampaign && data.SelectedCampaign.length > 0) {
      query = { ...query, cid: { ["$in"]: data.SelectedCampaign } };
    }

    if (data.selectedUser && data.selectedUser.length > 0) {
      query = { ...query, caller: { ["$in"]: data.selectedUser } };
    }

    if (user && ["field_exc", "agent"].indexOf(user.role) > -1) {
      query = { ...query, caller: user && user.username };
    }
    if (data.selectedDisposition && data.selectedDisposition.length > 0) {
      query = { ...query, disposition: { ["$in"]: data.selectedDisposition } };
    }

    if (data.startDate && data.endDate) {
      query = {
        ...query,
        createdOn: {
          $gte: new Date(data.startDate),
          $lt: new Date(data.endDate),
        },
      };
    }
    query = { ...query, admin: user && user.admin };


    if (data.callType) {
      query = { ...query, callType: data.callType };
    }

    return query;
  };


  const loadActivityData = async () => {
    debugger;


    let prospect_res = await API_POST("action/getdata", {
      root: "prospect_fields",
      con: { admin: user && user.admin, IsActivity: true },
    });

    if (prospect_res.data.length > 0) {

      let prospectFileds = prospect_res.data;


      for (let index = 0; index < prospectFileds.length; index++) {

        let checkexists = columns.find(v => v.name == prospectFileds[index].display_Name);
        if(!checkexists && prospectFileds[index].IsActivity == true){
    
          columns.push({
            name: prospectFileds[index].display_Name,
            selector: prospectFileds[index].field_name,
          });
      }

      }

      //    checkprospect.push(tempcheckprospect)

    }



    // let con = {
    //   admin: user && user.admin,
    //   createdOn: {
    //     $gte: new Date(startDate),
    //     $lt: new Date(endDate),
    //   },

    //   // $or: [{ IsActive: { $exists: false } }, { IsActive: true }],
    // };
    // if (SelectedCampaign && SelectedCampaign.length > 0) {
    //   con={  ...con,cid: { ["$in"]: SelectedCampaign }}
    // }
    // if (selectedUser && selectedUser.length > 0) {
    //   con={  ...con,assignTo: { ["$in"]: selectedUser }}
    // }

    // if (user && ["field_exc", "agent"].indexOf(user.role) > -1) {
    //   con = { ...con, assignTo: user && user.username };

    // }
    if (!user || (user && !user.admin)) {
      return;
    }

    let con = await pageLoadquery();

    let body = {
      limit: countPerPage,
      skip: skip,
      sort: { createdOn: -1 },
    };
    setQuery(con);

    let res;

    if(user.role == 'manager'){
      let managerTeam =  await getManagerTeam(user);
      console.log("teams manager",managerTeam)
      const managetCallers = { caller: { $in: managerTeam.map(v=>v.username) } };
      if (con['$and']) {
        // If $and already exists, push the new condition inside
        con['$and'].push(managetCallers);
      } else {
        // If $and doesn't exist, create it with the new condition
        con['$and'] = [managetCallers];
      }
    }


    

    if (con.callType && con.callType == 'personal') {

      delete con.callType;

      con = { ...con, pid: { $exists: false } };

      res = await API_POST("action/getPaginationDataActivity", {
        root: "CallDetails",
        querydata: con,
        body: body,
      });

    } else {
      res = await API_POST("action/getPaginationDataActivity", {
        root: "CallHistory",
        querydata: con,
        body: body,
      });

    }





    debugger;
    setData(res.data || []);
  };

  useEffect(() => {
    if(user && user.admin){
      loadActivityData();
    }

  }, [page]);

  const handleUrl = async (data) => {
    let query;
    query = await handlequery(data, query);
    let search = btoa(JSON.stringify(query));
    const { history } = props;

    history.push({
      pathname: `/admin/CustActivity`,
      search: `Search=${search}`,
    });
    //loadActivityData();
  };

  const handleExportExcel = async (e) => {
    let downloadFile = await API_POST(`action/ExportExcel`, {
      querydata: Query,
      body: { admin: user.admin, user: user.username, page: "CallHistory" },
    });
    if (downloadFile.status == 200) {
      setNotify({
        open: true,
        message: "File is in process,Please check in system log section.",
        color: "success",
      });
    }
  };

  const filterData = (data) => {
    debugger;
    setStartDate(data.startDate);
    setEndDate(data.endDate);
    setMobileNo(data.mobileNo);
    setSelectedUser(data.selectedUser);
    setSelectedCampaign(data.SelectedCampaign);
    setSelectedCallType(data.callType);
    setIsRefresh(true);
    // setTimeout(function(){ loadActivityData()}, 500)
    setOpen(false);
    handleUrl(data);
  };
  useEffect(() => {
    if (IsRefresh) {
      loadActivityData();
      setIsRefresh(false);
    }
  }, [IsRefresh]);

  return (
    <>
      <Snackbar
        place="tr"
        color={notify.color}
        message={notify.message}
        open={notify.open}
        closeNotification={() => setNotify({ open: false })}
        close
      />
      {/* <Card>
        <CardHeader color="transparent"> */}
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <h3>
            Activity
            <span style={{ float: "right", fontSize: "15px" }}>
              <Button
                size="sm"
                title="Filter"
                color="info"
                onClick={() => {
                  setOpen(true);
                }}
              >
                <i className="fa fa-filter" /> &nbsp; Filter
              </Button>
              {["manager", "owner"].indexOf(user && user.role) > -1 && (
                <Button
                  size="sm"
                  color="transparent"
                  justIcon
                  title="Export To Excel"
                  onClick={() => {
                    handleExportExcel();
                  }}
                >
                  <GetAppOutlinedIcon />
                </Button>
              )}
            </span>
            <GridItem  style={{float: "right", marginTop: "10px"}}>
              <InfoBox name={'Activity.Activity'} />
            </GridItem>
          </h3>
                

        </GridItem>

        {open && (
          <Filters
            open={open}
            handleClose={() => {
              setOpen(false);
            }}
            filterData={filterData}
            user={user}

          />
        )}
      </GridContainer>
      {/* </CardHeader> */}

      <GridContainer >
        <GridItem xs={12} sm={12} md={12} style={{minHeight: '200px', padding: '0 20px !important'}}>
        {data && !data.activityData && <div> <Loader></Loader></div>}

          {data && data.activityData && !isMobile &&
            <DataTable
              columns={columns}
              data={data.activityData || []}
              highlightOnHover
              pagination
              paginationServer
              paginationTotalRows={data.total}
              paginationPerPage={countPerPage}
              paginationComponentOptions={{
                noRowsPerPage: true,
              }}
              onChangePage={handleChangePage}
            />
          }
          {data && data.activityData && isMobile &&
            <DataTableMobile
              columns={columns}
              data={data.activityData || []}
              highlightOnHover
              pagination
              paginationServer
              paginationTotalRows={data.total}
              paginationPerPage={countPerPage}
              paginationComponentOptions={{
                noRowsPerPage: true,
              }}
              onChangePage={handleChangePage}
            />
          }
        </GridItem>
      </GridContainer>
      {/* </Card> */}
    </>
  );
}

export default Activity;
