import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import dayjs from "dayjs";
//import axios from 'axios';
import { Info, InfoOutlined } from "@material-ui/icons";
import Button from "components/CustomButtons/Button.jsx";
import { API_GET, API_POST } from "../../services/api.service";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import ProspectHistoryFilter from "./ProspectHistoryFilter";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import Snackbar from "../../components/Snackbar/Snackbar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import InfoBox from "../../components/InfoIcon/InfoIcon";
import ChangeCampaign from "./ChangeCampaign";
import { getMappedTeamData } from "../common/helper";



const columns = [
  {
    name: "Phone",
    selector: "phone",
  },
  {
    name: "First Name",
    selector: "firstName",
  },
  {
    name: "Source",
    selector: "source",
  },
  {
    name: "API Source",
    selector: "InSystem",
  },
  {
    name: "Status",
    selector: "updateStatus",
   
  },
  {
    name: "Campaign",
    cell: (row) => (
     <span> {row['campaignDetails']['name']} </span>
    )
  },
  {
    name: "Assignto",
    width:"180px",
    cell: (row) => (
     <span> {row['assignTo']} </span>
    )
  },
  {
    name: "Assignto Name",
    cell: (row) => (
     <span> {getMappedTeamData(row['assignTo'])} </span>
    )
  },
  {
    name: "Disposition",
    cell: (row) => {
      let arr = [];
      let value = row;
      if (value.dispositionArray && value.dispositionArray.length > 0) {
        for (
          let index = 0;
          index < value.dispositionArray.length;
          index++
        ) {
          const element = value.dispositionArray[index];
          arr.push(element.disposition_value);
        }
      }

      return arr.join(" > ");
    },
  },
  {
    name: "Created On",
    cell: (row) => (
      <span title={ JSON.stringify(row.differenceData,true)  } >{dayjs(row.createdOn).format("DD/MM/YYYY HH:mm")} <InfoOutlined style={{ color:'red'}} title={ JSON.stringify(row.differenceData,true)  } /></span>
    ),
  },
];





function ProspectHistory(props) {
  const [open, setOpen] = useState(false);
  const { classes, user } = props;
  const [data, setData] = useState({});
  const [adminData, setadminData] = useState(null);
  const [page, setPage] = useState(1);
  const countPerPage = 100;
  const [SelectedStatus, setSelectedStatus] = useState([]);
  const [prospectId,setProspectId]=useState([]);
  const [query,setQuery]=useState({});
  const [apiProspect,setApiProspect]=useState(false);
  const urlParams = new URLSearchParams(props.location && props.location.search);
  const fid = urlParams.get("fid");
  const phone = urlParams.get("phone");
  const [IsCampainChange, setIsCampainChange] = useState(false);
  const [InputType, setInputType] = useState("");
  const [options] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");

  const repeat = urlParams.get("repeat");
  const [notify, setNotify] = useState({
    open: false,
    message: null,
    color: "success",
  });

  const handleProspectCampain = () => {
    setIsCampainChange(true);
  };

  const pageLoadquery = async () => {
    debugger;
    let con;
   
    try{

      const urlParam = new URLSearchParams(window.location.search);
      let search = urlParam.get("Search");
  
      if (!search) {
        if (fid) {
          con = { ...con, fid };
        } else {
          con = { ...con };
        }
      } else {
        if (search) {
          search = JSON.parse(atob(search));
        }
  
        con = { ...search, admin: user && user.admin };
  
        //   if (user && ["field_exc", "agent"].indexOf(user.role) > -1) {
        //     con = { ...con, caller: user && user.username };
        //   }
      }
    }catch(e){

    }

    return con;
  };

  const handleExportExcel = async (e) => {
    let downloadFile = await API_POST(`action/ExportExcel`, {
      querydata: {admin: user && user.admin,fid:fid ? fid :null,...query },
      body: {  admin: user.admin, user: user.username,page:"prospecthistory" },
    });
    if (downloadFile.status == 200) {
      setNotify({
        open: true,
        message: "File is in process,Please check in file section.",
        color: "success",
      });
    }
  };

  const getUserList = async () => {
    if (user && user.admin) {
      //let con = await pageLoadquery();
        let con = { admin: user && user.admin, createdOn:{$lte:new Date(new Date().setHours(23, 59, 59, 0)),$gte:new Date(new Date().setHours(0,0,0,0))} };

        if (fid) {
          con = { ...con, fid };
        }else if(phone){
          con = { ...con, phone };
        } else  {
          con = { ...con, "InSystem": { $regex: "^API"}};
        }

        if(repeat) {
          con = { ...con , $or:[{updateStatus : 'Inserted'},{updateStatus:'DuplicateData'},{updateStatus:'UpdateData'}]};
        } 

        if (SelectedStatus) {
          con = { ...con, ...SelectedStatus  };
        }

        if(user.is_superuser){
          delete con.admin;
        }

        setQuery(con)
      let res = await API_GET(
        `action/getProspectHistory?page=${page}&per_page=${countPerPage}&delay=1`,
        null,
        {
          query: con,
        }
      );
      // debugger;
      setData(res.data);

      let d=[];
      for (let i=0;i<res.data.data.length;i++){
        d.push(res.data.data[i]._id);
      }
      setProspectId(d);

    }
  };

  const filterData = async (data) => {
    //handleUrl(data);
    setQuery(data);
    setSelectedStatus(data);
   
  };

//   const handleUrl = async (data) => {
//     let query;
//     query=data;
//     let search = btoa(JSON.stringify(query));
//     const { history } = props;

//     history.push({
//       pathname: `/admin/ProspectHistory?fid=${fid}`,
//       search: `Search=${search}`,
//     });
//   };


  const loadApiProspects =async () =>{
    let con = { admin: user && user.admin };

    if (fid) {
      con = { ...con, fid };
    } else {
      con = { ...con, "InSystem": { $regex: "^API"}};
    }
    let res = await API_POST(
        `action/getdata`,
        {
          root:'prospects',
          con: con,
        }
    );
    // debugger;
     setData(res);
    console.log(con);

    let d=[];
    for (let i=0;i<res.data.length;i++){
      d.push(res.data[i]._id);
    }
    setProspectId(d);


  }
  const handleClose = () => {
    setOpen(false);
    setIsCampainChange(false)
  };
  const apiLog = (e) => {
    setApiProspect(true);
      loadApiProspects();
  };
  useEffect(() => {
    
    getUserList();
    getAdminData();
  }, [page,SelectedStatus]);


  useEffect(() => {

      getAdminData();
    
  }, [user]);

  const getAdminData = async (e) => {

    try{

      let con = { ...query,admin:user.admin,createdOn:query.createdOn?query.createdOn :{$lte:new Date(new Date().setHours(23, 59, 59, 0)),$gte:new Date(new Date().setHours(0,0,0,0))} ,"InSystem": { $regex: "^API"} };

      if(user.is_superuser){
        delete con.admin;
      }

      let request = await API_GET(
        `action/getProspectHistory?page=1&per_page=100000&delay=1`,
        null,
        {
          query: con,
        }
      );


      let records = {
        Inserted:0,
        DuplicateData:0,
        RepeatedData:0,
        UpdateData:0,
      }
      if (request && request.status == 200) {

        request.data.data.map((v)=>{

          if(v.updateStatus == 'Inserted')  records.Inserted++;

          if(v.updateStatus == 'DuplicateData')  records.DuplicateData++;

          if(v.updateStatus == 'RepeatedData')  records.RepeatedData++;

          if(v.updateStatus == 'UpdateData')  records.UpdateData++;



        });

        setadminData(records)

      }


    }catch(e){

      console.log("yyyyyyyyyyyy",e)
    }

  };

  const makeFresh = async (e) =>{

    let result = window.confirm("Do you want to update all prospects");
    let Isupdated = result ? result : false;

    if(!Isupdated){
      return true;
    }

    let con = { ...query,admin:user.admin,createdOn:query.createdOn?query.createdOn :{$lte:new Date(new Date().setHours(23, 59, 59, 0)),$gte:new Date(new Date().setHours(0,0,0,0))} ,"InSystem": { $regex: "^API"} };


    if(!con.admin){
      return true;
    }

    let res = await API_GET(
      `action/getProspectHistory?page=1&per_page=500&delay=1`,
      null,
      {
        query: con,
      }
    );

    if(res.data.data && res.data.data.length){
      let prosIds = [];
      res.data.data.map((v)=>{
        prosIds.push(v.pid);
      })    

      if(prosIds.length){
        
        API_POST("action/updateMany", {
          root: "prospects",
          body: { $unset:{ disposition : 1,dispositionArray: 1,callback: 1 } },
          querydata: {_id:{$in:prosIds},admin:user.admin},
        });
        API_POST("action/updateMany", {
          root: "prospects",
          body: { assignTo : '',assignedBy: '',callDialed:false , open:true, makeFreshFromHistory:true } ,
          querydata: {_id:{$in:prosIds},admin:user.admin},
        });

        
       console.log(prosIds)
      }
    }
    setNotify({
      open: true,
      message: "Fresh successfully",
      color: "success",
    });
  }


  return (
    <div className="App">

      <Snackbar
          place="tr"
          color={notify.color}
          message={notify.message}
          open={notify.open}
          closeNotification={() => setNotify({ open: false })}
          close
      />

{IsCampainChange && (
          <ChangeCampaign
            Query={query}
            IsCampainChange={IsCampainChange}
            handleClose={() => {
              setIsCampainChange(false);
            }}
            user={user}
          />
        
      )}




      <Card>


        <CardBody>



<h2>Live Data
  <GridItem style={{ float: "right" }}>
    <InfoBox name={'SystemLogs.ProspectLog'} />
  </GridItem>
  <span style={{ float: "right", fontSize: "25px" }}>
                        <Button
                      size="sm"
                      color="info"
                      title="Filter"
                      onClick={() => {
                        setOpen(true);
                      }}
                  >
                         <i className="fa fa-filter" /> &nbsp; Filter
                  </Button>

        
                    <Button
                      size="sm"
                      color="info" 
                      title="Make Fresh"
                      onClick={() => {
                        makeFresh();
                      }}
                  >
                    Make Fresh
                  </Button>
                  {["manager", "owner"].indexOf(user && user.role) > -1 && (
                <Button
                  size="sm"
                  title="Change Campaign"
                  color="primary"
                  onClick={handleProspectCampain}
                >
                  <i className="fa fa-bullhorn" /> &nbsp; Change Campaign
                </Button>
              )}
                  <Button
                      size="sm"
                      color="transparent"
                     
                      justIcon
                      title="Export To Excel ( Inserted Prospect will not be downloaded )"
                      onClick={() => {
                        handleExportExcel();
                      }}
                  >
                    <GetAppOutlinedIcon  style={{fontSize:"25px"}} />
                  </Button>
                </span>
</h2>


<GridContainer>

<Box label="Inserted" value={adminData && adminData.Inserted} />
<Box label="DuplicateData" value={adminData && adminData.DuplicateData} />
<Box label="RepeatedData" value={adminData && adminData.RepeatedData} />
<Box label="UpdateData" value={adminData && adminData.UpdateData} />



</GridContainer>


   </CardBody>

</Card>


                  {
                    open && <ProspectHistoryFilter show props={props} user={user} open={open} filterData={filterData} handleClose={handleClose} />

                  }
      


        <Card>
        <GridContainer>
{/*

          {
            apiProspect ?  <Tab label={"Api Logs"} onChange={apiLog} style={{background:'#265077'}}></Tab>  :   <Tab label={"Api Logs"} onChange={apiLog}></Tab>
          }

*/}


          <GridItem xs={12} sm={12} md={12}>
         
                
            <DataTable
              title="Prospect Logs"
              columns={columns}
              data={data.data}
              highlightOnHover
              pagination
              paginationServer
              paginationTotalRows={data.total}
              paginationPerPage={countPerPage}
              paginationComponentOptions={{
                noRowsPerPage: true,
              }}
              onChangePage={(page) => setPage(page)}
            />
          </GridItem>
        </GridContainer>
        
        <b> Note: </b> <br/>    
        <GridContainer>
               
        <GridItem xs={6} sm={6} md={6}>
        <b> Insert - </b> Prospect get created Successfully <br/>
            <b> Repeat - </b>  Tried to create the prospect, on same day or in same CSV file
          </GridItem>
          <GridItem xs={6} sm={6} md={6}>

          <b> Duplicate -</b>  Try to create with all column which is already exist with the prospect earlier. <br/>
          <b> Updated -</b>  Prospect was exist, but some new column are get added or updated 

          </GridItem>


         </GridContainer> 
         
           
      </Card>
    </div>
  );
}

function Box(props){

  return (<>

<GridItem xs={12} sm={3} md={3}>
<Card>
<CardHeader>
<b><h6>{props.label}</h6> </b>
{ <h4>
    {props && props.value }
</h4>}
</CardHeader>
</Card>
</GridItem>

  </>)


}

export default ProspectHistory;
