import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomTable from "components/Table/Table.jsx";

const useStyles = makeStyles((theme) => ({
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
}));

const ReportTable = ({ columns, data }) => {
  const classes = useStyles();

  const tableHead = columns.map(column => column.label);
  const tableData = data.map(row => 
    columns.map(column => {
      const value = row[column.id];
      return value !== undefined && value !== null ? value.toString() : 'N/A';
    })
  );

  return (
    <Card>
      {/* <CardHeader color="primary">
        <h4 className={classes.cardTitleWhite}>Report Data</h4>
        <p className={classes.cardCategoryWhite}>
          Detailed view of your report
        </p>
      </CardHeader> */}
      <CardBody>
        <CustomTable
          tableHeaderColor="primary"
          tableHead={tableHead}
          tableData={tableData}
        />
      </CardBody>
    </Card>
  );
};

export default ReportTable;
