// src/components/RMActivities.js
import React, { useEffect, useState } from 'react';
import { Container, Typography, Box, CircularProgress } from '@material-ui/core';
import ReportDashboard from 'components/Reports/ReportDashboard'; // Update this path as needed
import { API_POST } from "../../services/api.service";

const RMActivities = () => {
    const [reportData, setReportData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [data, setData] = useState([]);
    const [pivotState, setPivotState] = useState({
        rows: ['RMName','status'],
        cols: [],
        vals: ['Count'],
        aggregatorName: 'Count',
        rendererName: 'Table',
        sorters: {
          RMName: (a, b) => a.localeCompare(b),
          status: (a, b) => a.localeCompare(b),
        },
        valueFilter: {
          Count: { min: 0 }
        },
        hiddenAttributes: ['allocatedTo'],
      });
    // Updated dynamic columns
    const columns = [
        { id: 'RMName', label: 'Customer Success Manager' },
        { id: 'username', label: 'User Name' },
        { id: 'status', label: 'Status' },
        { id: 'Active', label: 'Active' },
        { id: 'TicketCount', label: 'Ticket Count' },
    ];

    const loadReports = async (filters = {}) => {
        try {
            setLoading(true);
            const data = await API_POST("report/adminRMReport", filters);

            console.log("loadReports", data);
            if (data.status === 200) {
                setReportData(data.data.data);
            }

            setError(null); // Reset any previous errors
        } catch (err) {
            console.error('Error fetching reports:', err);
            setError('Failed to fetch reports. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadReports(); // Fetch initial data
    }, []);

    return (
        <Container maxWidth="lg">
            <Box my={4}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Subscriber vs RM Data
                </Typography>
                {loading && <CircularProgress />}
                {error && <Typography color="error">{error}</Typography>}
                {!loading && !error && (
                    
                    <ReportDashboard 
                        reportData={reportData} 
                        loadReports={loadReports} 
                        columns={columns}  // Pass columns to ReportDashboard
                        defaultViewMode='pivotTable'
                        defaultPivotKeys={pivotState}
                        enableMode={true}
                    />
                )}
            </Box>
        </Container>
    );
};

export default RMActivities;



