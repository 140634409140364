import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import TextField from "@material-ui/core/TextField";
import { API_POST } from "../../services/api.service";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";


import Chip from "@material-ui/core/Chip";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import DialogTitle from "../../components/DialogTitle/DialogTitle";
import { getMappedTeamData } from "../common/helper";

const style = {
  disposition: {
    display: "inline-block",
    width: "100%",
  },
  dispositionActions: {
    float: "right",
  },
};

function ProspectAssignment(props) {
  debugger;
  const { cid, IsAssignment, allProspects, selectedRows, onChange, user } = props;

  const [Notify, setNotify] = useState({
    open: false,
    message: "",
    color: "success",
  });
  const [allUsers, setAllUsers] = useState([]);
  let [InputType, setInputType] = useState("");

  const getCampaignUsers = async () => {
    let registerRequest;
    try {
      registerRequest = await API_POST("action/getdata", {
        root: "campaignMembers",
        //Change according to roleid --only visible agent and field executive
        //con: { role: user && user.role ,cid:cid },
        con: { cid: cid },
      });

      if (registerRequest && registerRequest.status == 200) {
        setAllUsers(registerRequest && registerRequest.data);
      }
    } catch (error) { }
  };

  const handleSetAssignment = (Type, InputType, selectedProspects) => {
    debugger;
    if (Type != "selectedAll" && selectedProspects.length == 0) {
      alert("Please select Prospects");
      return;
    }

    let Isupdated = true;
    Isupdated = InputType ? true : alert("Please select User");

    if (Type === "selectedAll" && Isupdated == true) {
      let result = window.confirm("Do you want to update all prospects");
      Isupdated = result ? result : false;
      Isupdated && updateAssignment(InputType, props.Query);
      // InsertAssignmentHistory(InputType, props.Query);
    } else {
      Isupdated = true;
      for (var row in selectedProspects) {
        let query = { _id: selectedProspects[row]._id };

        updateAssignment(InputType, query);
        InsertAssignmentHistory(InputType, selectedProspects[row]);
      }

      Isupdated && setNotify({
        open: true,
        message: "Assignment change successfully",
        color: "success",
      });

      if (onChange) {
        onChange();
      }

      setTimeout(function () {
        props.handleClose();
        props.RemoveAllChecks(false);
      }, 3000);
    }
  };

  const updateAssignment = async (User, query) => {
    let registerRequest;

    registerRequest = await API_POST("action/updateMany", {
      root: "prospects",
      body: { assignTo: User.username, assignedBy: user && user.username },
      querydata: query,
    });

    if (registerRequest && registerRequest.status == 200) {
      console.log("Change successfully");
      setNotify({
        open: true,
        message: "Assignment change successfully",
        color: "success",
      });
    }
  };

  const InsertAssignmentHistory = async (User, prospect) => {
    debugger;
    let registerRequest;
    let formValue = {};

    formValue["pid"] = prospect._id;
    formValue["assignTo"] = User.username;
    formValue["leadType"] = prospect.assignTo ? "ReAssigned" : "Fresh";
    formValue["leadSource"] = "Manual";
    formValue["cid"] = cid;
    formValue["createdOn"] = new Date();
    formValue["admin"] = prospect.admin;
    formValue["assignedBy"] = user && user.username;

    registerRequest = await API_POST("action/addRecord", {
      root: "AssignmentHistory",
      body: formValue,
    });

    if (registerRequest && registerRequest.status == 200) {
      console.log("Change successfully");
    }
  };

  useEffect(() => {
    getCampaignUsers();
  }, []);

  return (
    <>
      <Snackbar
        place="tr"
        color={Notify.color}
        message={Notify.message}
        open={Notify.open}
        autoHideDuration={3000}
        closeNotification={() => setNotify({ open: false })}
        close
      />

      <GridContainer>
        <Dialog open={IsAssignment} aria-labelledby="form-dialog-title">
          <DialogTitle id="customized-dialog-title" onClose={props.handleClose}>
            Assignment
          </DialogTitle>

          <DialogActions>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Autocomplete
                  onChange={(event, value) => setInputType(value)}
                  id="combo-box-demo"
                  options={allUsers}
                  labelText="FieldType"
                  name="autoComplete"
                  value={InputType || null}
                  getOptionLabel={(allUsers) => allUsers.username +"("+getMappedTeamData(allUsers.username)+")" || ""}
                  defaultValue="Select"
                  renderTags={(value) =>
                    value.map(() => (
                      <Chip variant="outlined" label="Enter Field" />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select User"
                      variant="outlined"
                    />
                  )}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={12}>
                <Button
                  onClick={() => {
                    handleSetAssignment("selected", InputType, selectedRows);
                  }}
                  color="primary"
                >
                  Selected - {selectedRows.length > 0 ? selectedRows.length : 0}
                </Button>
                <Button
                  onClick={() => {
                    handleSetAssignment("selectedAll", InputType, allProspects);
                  }}
                  color="primary"
                >
                  Selected All - {props.totalRowCount ? props.totalRowCount : 0}
                </Button>
                {/* <Button onClick={props.handleClose} color="primary">
                  Close
                </Button> */}
              </GridItem>
            </GridContainer>
          </DialogActions>
        </Dialog>
      </GridContainer>
    </>
  );
}

ProspectAssignment.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(style)(ProspectAssignment);
