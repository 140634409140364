/* eslint-disable */
import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
// core components

import Snackbar from "components/Snackbar/Snackbar.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Typography from "@material-ui/core/Typography";
import PhoneIcon from "@material-ui/icons/Phone";
import Loader from "components/Loader/Loader.jsx";

import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { API_GET, API_POST } from "../../services/api.service";
import CustomTabs from "../../components/CustomTabs/CustomTabs";
import ErrorBoundary from "../../hoc/ErrorBoundary";
import Activity from "../Activity/Activity";
import CallDetail from "../Activity/CallDetail";
import CallRecording from "../Activity/CallRecording";
import {Box, Tab, Tabs} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
// import InfoIcon from "@material-ui/icons/Info";
import InfoBox from "../../components/InfoIcon/InfoIcon";



const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

class CampInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      aggData: {},
      activeCamp:0,
      inActiveCamp:0
    };
  }

  loadCampaign = async (id) => {
    let registerRequest;
    try {
      registerRequest = await API_GET("action/CampaignAggregateData?cid=" + id);

      if (registerRequest && registerRequest.status == 200) {
        //console.log(registerRequest.data);
        if (registerRequest.data) {

          this.setState({ aggData: registerRequest.data });
        }
        //this.setState({ campaignsDetails: registerRequest.data });
      }
    } catch (error) {
      console.log(error);
    }
  };

  componentWillMount() {
    const { cid } = this.props;
    if (cid) {
      this.setState({ cid: cid });
      this.loadCampaign(cid);
    }
  }

  render() {
    const { aggData } = this.state;

    return (
      <>
        <Typography variant="caption" display="block" gutterBottom>
          <i class="fa fa-clock-o" aria-hidden="true" /> &nbsp;
          {aggData && aggData.pendingProspects}
          &nbsp;Pending calls
        </Typography>
        <Typography variant="caption" display="block" gutterBottom>
          <i class="fa fa-line-chart" aria-hidden="true" />
          &nbsp;
          {aggData && aggData.upcomingProspects}
          &nbsp;Upcoming followups
        </Typography>
        <Typography variant="caption" display="block" gutterBottom>
          <i class="fa fa-exclamation-triangle" aria-hidden="true" />
          &nbsp;
          {aggData && aggData.overdueProspects} &nbsp;Overdue followups
        </Typography>
      </>
    );
  }
}

class Campaigns extends React.Component {
  constructor(props) {
    super(props);

    let userExpired = false;
    var todayDate = new Date().getTime();  
    var expiry = new Date(props.user.expireon).getTime();  


    if(todayDate > expiry && props.user && props.user.expireon){
     userExpired = true;
    }


    this.state = {
      campaignList: [],
      activeCamp:0,
      inActiveCamp:0,
      notify: {
        open: false,
        message: "",
        color: "success",
      },
      IsChecked: true,
      userExpired:userExpired
    };
  }
  // to stop the warning of calling setState of unmounted component
  componentDidMount() {
    const { user } = this.props;

    if (user && user.admin) {
      if (user && ["field_exc", "agent"].indexOf(user.role) > -1 && !this.state.userExpired) {
        this.getUserBsdCampaign();
      } else {

        if(!this.state.userExpired)
        this.loadCampaigns();
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.IsChecked !== this.state.IsChecked && !this.state.userExpired) {
      this.setState({ campaignList: [] });
      this.loadCampaigns();
      
    }
  }

  // getUserBsdCampaign = async () => {
  //   let RegisterCampain;
  //   let userBsdCampaigns = [];
  //   const { user } = this.props;

  //   if (user && ["field_exc", "agent"].indexOf(user.role) > -1) {
  //     RegisterCampain = await API_POST("action/getdata", {
  //       root: "campaignMembers",
  //       con: {
  //         $and: [
  //           { $or: [{ IsActive: { $exists: false } }, { IsActive: true }] },
  //           { admin: user && user.admin },
  //           { username: user.username },
  //         ],
  //       },
  //     });
  //     if (RegisterCampain && RegisterCampain.status == 200) {
  //       for (let index = 0; index < RegisterCampain.data.length; index++) {
  //         const element = RegisterCampain.data[index];
  //         let Request;

  //         Request = await API_POST("action/getdata", {
  //           root: "campaigns",
  //           con: {
  //             _id: element.cid,
  //           },
  //         });
  //         debugger;

  //         if (Request && Request.status == 200 && Request.data[0] && Request.data[0].IsActive) {
  //           userBsdCampaigns.push(Request.data[0]);
  //         }
  //       }
  //       this.setState({ campaignList: userBsdCampaigns });
  //     }
  //   }
  // };

  getUserBsdCampaign = async () => {
    debugger;
    const { user } = this.props;
    let registerRequest;
    try {
      registerRequest = await API_POST("action/getdata", {
        root: "campaigns",
        con: {
          $and: [{ IsActive: true }, { campaignMembers: user.username }],
        },
      });
      if (registerRequest && registerRequest.status == 200) {
        this.setState({ campaignList: registerRequest.data });
      }
    } catch (error) {
      console.log(error);
    }
  };

  loadCampaigns = async (userBsdCampaigns) => {
    debugger;
    const { user } = this.props;
    let activeCampList = {
      active:0,
      inActive:0
    }
    let registerRequest;
    let IsActive = this.state.IsChecked == true ? true : false;
    try {
      registerRequest = await API_POST("action/getdata", {
        root: "campaigns",
        con: { admin: user && user.admin, IsActive },
        //cols: {}
      });


      let totalregisterRequest = await API_POST("action/getdata", {
        root: "campaigns",
        con: { admin: user && user.admin },
        //cols: {}
      });

      if(["manager"].indexOf(user && user.role) > -1){
        
        if (registerRequest && registerRequest.status == 200) {
         
          let campFilter=totalregisterRequest.data.filter(function(val){
            console.log(val)
            val.IsActive?activeCampList.active++ :  activeCampList.inActive++
            if(val.campaignMembers && val.campaignMembers.includes(user && user.username)){
              
              return true;
            }else{
              return false;
            }
          })
          console.log(campFilter)

          this.setState({campaignList:campFilter});
        }

      }else{
        totalregisterRequest.data.map((val)=> {val.IsActive?activeCampList.active++ :  activeCampList.inActive++ })
        if (registerRequest && registerRequest.status == 200) {
          this.setState({ campaignList: registerRequest.data });
        }
      }




      this.setState({ activeCamp: activeCampList.active,inActiveCamp :activeCampList.inActive });


     
    } catch (error) {
      console.log(error);
    }
  };

  handleEdit = async (item) => {
    const { history } = this.props;
    history.push("/admin/editcampaigns/" + item._id);
  };
  handleAddProspect = async (item) => {
    const { history } = this.props;
    history.push("/admin/AddProspect/" + item._id);
  };
  handleAdd = async () => {
    const { history } = this.props;
    history.push("/admin/addcampaigns");
  };

  handleMoreInfo = async (item) => {
    const { history } = this.props;
    history.push("/admin/CampainMoreInfo/" + item._id);
  };

  StartCall = async (type, cid) => {
    const { user, history } = this.props;
    let registerRequest;
    try {
      registerRequest = await API_POST("action/StartCall", {
        cid,
        username: user && user.username,
        type: type,
      });

      if (registerRequest && registerRequest.status == 200) {
        console.log(registerRequest.data);
        if (
          registerRequest.data &&
          registerRequest.data.data &&
          registerRequest.data.message == ""
        ) {
          let prospect = registerRequest.data.data;
          localStorage.setItem("callingType", type);
          history.push("/admin/ViewProspect/" + prospect._id);
        } else {
          this.setState({
            notify: {
              open: true,
              message: "No Prospect found to call.",
              color: "danger",
            },
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  handleDelete = async (item) => {
    let registerRequest;
    let IsActive = false;

    registerRequest = await API_POST("action/update", {
      root: "campaigns",
      body: { IsActive },
      querydata: { admin: this.props.user.admin, _id: item._id },
    });
    if (registerRequest && registerRequest.status == 200) {
      this.setState({
        notify: {
          open: true,
          message: "Campaign De-active Successfully.",
          color: "danger",
        },
      });
    }
  };

  handleCheck = (event) => {
    let cheked = this.state.IsChecked ? 0 :1;
    this.setState({ IsChecked: cheked });
  };

  render() {
    const { classes, user } = this.props;
    const { campaignList,activeCamp ,inActiveCamp } = this.state;

    return (
      <GridContainer>
        <Snackbar
          place="tr"
          color={this.state.notify.color}
          message={this.state.notify.message}
          open={this.state.notify.open}
          closeNotification={() =>
            this.setState({
              notify: {
                open: false,
              },
            })
          }
          close
        />






        <Card>
          <CardHeader color="transparent">


            <GridItem xs={12} sm={12} md={12}>


              <Tabs
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: "#265077"
                    }
                  }}
                  value={this.state.IsChecked? 0 : 1}
                  onChange={  this.handleCheck}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
              >
                <Tab label={"Active- "+activeCamp} />

                {

                  user && user.role !='agent' ?   (<Tab label={"Inactive- "+inActiveCamp} />) :null

                }

              
              </Tabs>

              <GridContainer style={{ display: 'flex', justifyContent: 'flex-end'}}>
                <GridItem  style={{float: "right", marginTop:"-40px", zIndex: "1"}}>
                  <InfoBox name={'Campaigns'} />
                </GridItem>
              </GridContainer>


            </GridItem>


            <h3>
              {["manager", "owner"].indexOf(user && user.role) > -1 && (
                <Button
                  variant="transparent"
                  color="info"
                  size="sm"
                  style={{ float: "right" }}
                  onClick={() => this.handleAdd()}
                >
                  Create Campaign
                </Button>
              )}
            </h3>

          </CardHeader>
          <CardBody style={{minHeight: '200px'}}>
            <GridContainer
              justify={
                campaignList && campaignList.length == 0
                  ? "center"
                  : "flex-start"
              }
            >
              {campaignList && campaignList.length == 0 && <Loader />}

              {campaignList.map((item, index) => {
                return (
                  <GridItem key={index} xs={12} sm={4} md={3}>
                    <Card className="campaigns-wrap" style={{marginTop:'0.5rem'}}>
                      <CardHeader color="white">
                        <GridContainer>
                          <GridItem sm={12} md={12} xs={12}>
                            <p className="campaigns-title" title={item.name}> {item.name}</p>
                            <div class="border-t-2" />
                            <span className={item.IsActive ? "campaigns-sta" : "campaigns-sta-red"}>{item.IsActive ? 'Active' : 'In Active'}</span>
                          </GridItem>
                        </GridContainer>
                      </CardHeader>
                      <CardBody >
                        <div className="campaigns-item">
                          <CampInfo cid={item._id} />
                        </div>
                        <GridItem sm={12} md={8} xs={12}>
                          <GridContainer direction="row">
                            <GridItem xs={12} sm={6} md={3}>
                              <div className="campaigns-actions">
                                <a
                                  onClick={() =>
                                    this.StartCall("normal", item._id)
                                  }
                                >
                                  <i class="fa fa-phone-square" aria-hidden="true"></i>
                                </a>

                                {

                                  !item.disableManualProspectCreate && (
                                    <>
                                    <a onClick={() => this.handleAddProspect(item)}>
                                     <i class="fa fa-user-plus" />
                                     </a>
                                    </>
                                  )

                                }
                                


                                {["manager", "owner"].indexOf(
                                  user && user.role
                                ) > -1 && (
                                    <a onClick={() => this.handleEdit(item)}>
                                      <i class="fa fa-pencil-square-o" />
                                    </a>
                                  )}

                                {["manager", "owner"].indexOf(
                                  user && user.role
                                ) > -1 && (
                                    <a onClick={() => this.handleDelete(item)}>
                                      <i class="fa fa-trash" aria-hidden="true" />
                                    </a>
                                  )}
                              </div>
                            </GridItem>
                          </GridContainer>
                        </GridItem>
                      </CardBody>
                      <CardFooter className="campaigns-footer">
                        
                        <a
                          onClick={() => this.handleMoreInfo(item)}
                          style={{
                            position: "absolute",
                            right: "10px",
                            bottom: "10px",
                          }}
                        >
                          View More
                          <i class="fa fa-angle-right" aria-hidden="true"></i>
                        </a>
                      </CardFooter>
                    </Card>
                  </GridItem>
                );
              })}
            </GridContainer>
          </CardBody>
        </Card>
      </GridContainer>
    );
  }
}

export default withStyles(styles)(Campaigns);
