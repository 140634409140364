import React, { useState, useCallback } from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GenerateField from "components/CustomSelect/GenerateField.js";
import ReportFilters from './ReportFilters';
import ReportTable from './ReportTable';
import PivotTableUI from 'react-pivottable/PivotTableUI';
import 'react-pivottable/pivottable.css';

const ReportDashboard = ({
  reportData,
  loadReports,
  columns,
  defaultViewMode = 'reportTable',
  defaultPivotKeys,
  enableMode = true,
  enableFilter = true
}) => {
  const [viewMode, setViewMode] = useState(defaultViewMode);
  const [pivotState, setPivotState] = useState(defaultPivotKeys);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [filteredData, setFilteredData] = useState(reportData);

  const handleViewChange = useCallback((event) => {
    console.log(event)
    setViewMode(event.target.value);
  }, []);

  const handleFilterChange = useCallback((filters) => {
    const filteredResults = reportData.filter(item => {
      return Object.entries(filters).every(([key, value]) => {
        if (!value) return true; // Skip empty filters
        if (typeof item[key] === 'string') {
          return item[key].toLowerCase().includes(value.toLowerCase());
        }
        if (typeof item[key] === 'number') {
          return item[key] === parseFloat(value);
        }
        if (item[key] instanceof Date) {
          const itemDate = new Date(item[key]).toISOString().split('T')[0];
          return itemDate === value;
        }
        return true;
      });
    });
    setFilteredData(filteredResults);
  }, [reportData]);

  if (loading) return <Box display="flex" justifyContent="center"><CircularProgress /></Box>;
  if (error) return <Box display="flex" justifyContent="center">Error: {error}</Box>;

  return (
    <Card>
      {/* <CardHeader color="primary">
        <h4>Report Dashboard</h4>
        <p>Analyze and visualize your data</p>
      </CardHeader> */}
      <CardBody>
        {enableMode && (
          <Box mb={2}>
            <GenerateField
              id="view-mode-select"
              label="Select View"
              value={viewMode}
              onChange={handleViewChange}
              options={[
                { key: 'reportTable', value: 'Report Table' },
                { key: 'pivotTable', value: 'Pivot Table' }
              ]}
            />
          </Box>
        )}

        {viewMode === 'reportTable' && (
          <>
            {enableFilter && (
              <Box mb={2}>
                <ReportFilters data={reportData} columns={columns} onFilterChange={handleFilterChange} />
              </Box>
            )}
            <ReportTable columns={columns} data={filteredData && filteredData.length > 0 ? filteredData : reportData} />
          </>
        )}

        {viewMode === 'pivotTable' && (
          <Box mt={2}>
            <PivotTableUI
              data={reportData}
              onChange={s => setPivotState(s)}
              {...pivotState}
            />
          </Box>
        )}
      </CardBody>
    </Card>
  );
};

export default ReportDashboard;
